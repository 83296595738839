import React from 'react';
import { Container, Stack, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LabelObject, extractPrimaries } from '@utils/labels';
import Button from '@app/components-lib/components/Button';
import Markdown from '@components/Markdown';

export type TermsAcceptedProps = {
  labels: LabelObject;
  goTo: () => void;
};

const TermsAccepted = ({ labels, goTo }: TermsAcceptedProps) => {
  const { finalTopHeader, finalDescription, finalSubDescription, finalButtonText } = extractPrimaries(labels);
  return (
    <Container sx={(theme) => ({ margin: '0.5rem 0 0 0', [theme.breakpoints.up('md')]: { margin: '4rem 0' } })}>
      <Stack alignItems="center">
        <CheckCircleIcon
          htmlColor="#589533"
          fontSize="large"
          sx={{ width: '4rem', height: '4rem', marginBottom: '0.7rem' }}
        />
        <Typography component="h1" fontSize="2.1rem" fontWeight="900" sx={{ marginBottom: '2.25rem' }}>
          {finalTopHeader}
        </Typography>
        <Box
          textAlign="left"
          sx={(theme) => ({ marginBottom: '2rem', [theme.breakpoints.up('md')]: { marginBottom: '2.5rem' } })}
        >
          <Markdown>{finalDescription}</Markdown>
          <Typography component="p">{finalSubDescription}</Typography>
        </Box>
        <Stack alignItems="center" sx={{ width: '100%' }}>
          <Button sx={{ textTransform: 'uppercase', width: '100%', maxWidth: '32rem' }} onClick={goTo}>
            {finalButtonText}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default TermsAccepted;
