import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getVehicleById } from '@cv/portal-cps-lib/vehicle';
import { VehicleRequest } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import config from '@config/config';
import { VehicleDetails } from '@api/types';
import useAccount from './useAccount';
import { RootState } from '@app/reducers';

function useAllVehicles(): UseQueryResult<VehicleDetails[]> {
  const { data: account } = useAccount();
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId);
  const environmentEnum = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';

  return useQuery({
    enabled: Boolean(accessToken && account?._id),
    queryKey: ['allUserVehicles', account?._id],
    queryFn: async () => {
      const getAllVehicles = account?.vehicleIds?.map(async (vehicleId) => {
        const payload: VehicleRequest = {
          data: {
            id: vehicleId,
          },
          environment: environmentEnum,
          headers: {
            Authorization: accessToken,
            'Accept-Language': locale,
            'CV-Tenant-Id': tenantId,
          },
        };

        return getVehicleById(payload).then((response) => {
          return response?.data;
        });
      });

      const allVehicleDetails: VehicleDetails[] = (await Promise.all(getAllVehicles ?? [])) as VehicleDetails[];
      return allVehicleDetails.filter((vehicle) => !vehicle.billingSystem);
    },
    placeholderData: [],
  });
}

export default useAllVehicles;
