import React, { ReactNode, useMemo } from 'react';
import LabelContext, { BuildedLabels } from './LabelContext';
import { useLabelContext } from './useLabelContext';
import { ContentfulLabelType, LabelDictionary, ServerLabel } from '@utils/labels';

const toCamelCase = (s: string) => s.replace(/-./g, (x) => x[1].toUpperCase());
const addDot = (path: string) => (path ? path + '.' : '');

type LabelContextProps = {
  children: ReactNode;
  labels: ContentfulLabelType[];
  isolated?: boolean;
  name?: string;
};

const extractLabels = (
  labels:
    | Array<LabelDictionary | ContentfulLabelType | ServerLabel>
    | LabelDictionary
    | ContentfulLabelType
    | ServerLabel,
  prevLabels: BuildedLabels = {},
  path = '',
): BuildedLabels => {
  if (!labels) {
    return {};
  }

  if (!Array.isArray(labels)) {
    labels = [labels];
  }

  return labels.reduce(
    (acc, label) => {
      if ('dictionaryKey' in label) {
        if (acc[label.dictionaryKey]) {
          console.log(
            `Dictionary with key ${label.dictionaryKey} already exists in the context at path: ${addDot(path)}${
              label.dictionaryKey
            }`,
          );
        }
        const _transformedKey = toCamelCase(label.dictionaryKey);
        const newPath = `${addDot(path)}${_transformedKey}`.trim();
        acc[_transformedKey] = extractLabels(label.content, acc[label.dictionaryKey] as BuildedLabels, newPath);
      } else if ('labelKey' in label) {
        if (acc[label.labelKey]) {
          console.log(
            `Label with key ${label.labelKey} already exists in the context at path: ${addDot(path)}${
              label.labelKey
            } it will be overwritten`,
          );
        }
        const _transformedKey = toCamelCase(label.labelKey);
        acc[_transformedKey] = label.labelValue || '';
      }
      return acc;
    },
    { ...prevLabels },
  );
};

export const LabelContextProvider = ({ children, labels, isolated, name = '' }: LabelContextProps) => {
  const prevContext = useLabelContext();

  const labelsProxy = useMemo(() => {
    const handlers: ProxyHandler<BuildedLabels> = {
      get(target, path, receiver) {
        if (typeof path === 'symbol') {
          return true;
        }
        if (path in target) {
          const value = Reflect.get(target, path, receiver);
          if (typeof value === 'object' && value !== null) {
            return new Proxy(value, handlers);
          }
          return value;
        }

        console.log(`Label or dictionary with key ${addDot(name)}${String(path).toString()} not found in the context`);
        return '';
      },
    };

    const extractedLabels = extractLabels(labels, (isolated ? {} : prevContext) as BuildedLabels, name);
    return new Proxy(extractedLabels, handlers);
  }, [labels, isolated, prevContext, name]);

  return <LabelContext.Provider value={labelsProxy}>{children}</LabelContext.Provider>;
};
