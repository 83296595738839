import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRsaSignature } from '../../../../services/cps';
import { PaymentContainer } from '../styles';
import { ZuoraPaymentRequest, ZuoraResponse, ZuoraUserInfo } from '../zuora';
import Spinner from '../../../../components/Spinner';
import { ZUORA_URL } from '../../../Types';
import { useScript } from '@manageSubscription/utils/loadScript';
import { replaceTemplateString } from '@manageSubscription/utils';
import { buildZuoraPaymentRequest, buildZuoraUserInfo } from '../../../builders';
import Modal, { useModal } from '../../../../components/Modal';
import { OkModal } from '../../../../components/Modal/OkModal';
import { useUpdatePayment } from './useUpdatePayment';
import PaymentButton from '../PaymentButton';
import { usePaymentContext } from '../PaymentContext';

type ZuoraCallback = (response: ZuoraResponse) => void;

type ZuoraAPI = {
  runAfterRender(errorFn: () => void): void;
  sendErrorMessageToHpm(key: string, errorMessage: string): void;
  renderWithErrorHandler(
    params: ZuoraPaymentRequest,
    initFields: ZuoraUserInfo,
    callback: ZuoraCallback,
    errorCallback: (key: string, code: string, message: string, rawGatewayInfo: string) => void,
    width?: number,
    height?: number,
    removeCoverCallback?: ZuoraCallback,
  ): void;
  setAgreement(
    mitConsentAgreementSrc: string,
    mitProfileType: string,
    agreementSupportedBrands: string[],
    mitConsentAgreementRef: string,
  ): boolean;
  setEventHandler(actionName: string, handler: ZuoraCallback): void;
  submit(): boolean;
};

declare const Z: ZuoraAPI;

type NewPaymentProps = {
  cancelEdit: () => void;
};

const NewPayment = ({ cancelEdit }: NewPaymentProps) => {
  const { isFirstPaymentMethod } = usePaymentContext();

  const {
    scriptLoaderStatus: aScriptLoaderStatus,
    setUpdatedPayment,
    source,
    transactionId,
    clientIP,
    newTransaction,
    isLoading,
  } = useUpdatePayment({
    onDone: cancelEdit,
  });

  const {
    content: {
      commonWebContent: { okButtonLabel },
      paymentErrorMsg,
      paymentServerErrorMsg,
      assets,
    },
    subscriptionProps,
    send,
  } = usePaymentContext();
  const {
    supportedCreditCards,
    vehicleDetails: { vin },
    customerCareNumber,
  } = subscriptionProps;

  const isFormSubmit = useRef(false);
  const { isShown, toggleModal } = useModal();

  const handleModalClose = () => {
    toggleModal();
  };
  const [showComponent, setShowComponent] = useState(false);
  const [zScriptLoaderStatus, setZScriptLoaderSource] = useScript(null);

  const { status: rsaStatus, data: rsaSignatureResp } = useQuery(
    ['rsaSignature'],
    () => getRsaSignature(subscriptionProps),
    {
      cacheTime: 0,
      retry: false,
    },
  );

  useEffect(() => {
    if (!transactionId || aScriptLoaderStatus !== 'active') return;
    setZScriptLoaderSource(ZUORA_URL);
  }, [transactionId, aScriptLoaderStatus]);

  useEffect(() => {
    if (rsaStatus !== 'success' || zScriptLoaderStatus !== 'active' || !transactionId) return;

    renderZuora(rsaSignatureResp);
  }, [zScriptLoaderStatus, rsaStatus, rsaSignatureResp, transactionId]);

  const handlePaymentResponse = (response: ZuoraResponse) => {
    console.log('Zuora Payment Response: ', JSON.stringify(response));

    const { success: status, refId } = response;
    status === 'true' ? postPaymentSubmit(refId) : toggleModal();
  };

  const handlePaymentErrorResponse = (key: string, code: string, message: string) => {
    const errorResponse = { key, code, message };

    console.log('Zuora Payment Error Response: ', JSON.stringify(errorResponse));

    send?.({ type: 'unsetLoading' });

    errorResponse.key === 'error' ? toggleModal() : handleZuoraError(errorResponse);
  };

  const paymentErrorContent = () => {
    const message = replaceTemplateString(paymentErrorMsg, { customerCareNumber: customerCareNumber });
    return <OkModal onConfirm={handleModalClose} buttonText={okButtonLabel} message={message} />;
  };

  const reRenderZuora = (errorResponse: string) => {
    setShowComponent(false);
    Z.runAfterRender(handleZuoraError.bind(NewPayment, { message: errorResponse }));
    // Update transactionId which re-loads the script and re-renders Zuora
    newTransaction();
  };

  const handleZuoraError = ({ key = '', message = '' }) => {
    Z.sendErrorMessageToHpm(key, message);
  };

  const renderZuora = (rsaSignature) => {
    const request = buildZuoraPaymentRequest(subscriptionProps, rsaSignature, transactionId, clientIP, source);
    console.log('render zuora:', transactionId);
    const userInfo = buildZuoraUserInfo(subscriptionProps);
    Z.setEventHandler('onloadCallback', function () {
      setShowComponent(true);
    });
    Z.renderWithErrorHandler(request, userInfo, handlePaymentResponse, handlePaymentErrorResponse);
  };

  const handlePaymentSubmit = () => {
    if (!Z.setAgreement('External', 'Recurring', supportedCreditCards, vin)) {
      return;
    }

    send?.({ type: 'setLoading' });

    // Each call of line 138 register `handlePaymentResponse` one more time
    // when we submit form it calls this callback as many times as you registered
    // in that case we spam server with the same call what cause it to return 500
    // in some cases
    // Zuora doesn't have any callback to cleanup this
    // for more details on how zuora function work check
    // https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora.js
    isFormSubmit.current = true;
    Z.submit();
  };

  const postPaymentSubmit = (paymentMethodId: string) => {
    if (isFormSubmit.current) {
      // Triggers updatePayment call to set defaultPaymentMethod
      setUpdatedPayment({ paymentMethodId, defaultPaymentMethod: true });
      isFormSubmit.current = false;
    }
  };

  const handleCancel = () => {
    if (!isFirstPaymentMethod) {
      cancelEdit();
    }
  };

  return (
    <PaymentContainer>
      {(!showComponent || isLoading) && <Spinner />}
      <div id="zuora_payment"></div>
      {showComponent && (
        <>
          <PaymentButton data-testid="new-payment" onContinue={handlePaymentSubmit} onCancel={handleCancel} />
          <Modal assets={assets} content={paymentErrorContent()} isShown={isShown} hide={handleModalClose} />
        </>
      )}
    </PaymentContainer>
  );
};

export default NewPayment;
