import { buildOfferDetailKeys } from '@app/components-lib';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { CapableServiceDetails } from '@api';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { isEqual } from 'lodash';
import useEligiblePackages from '@api/queries/useEligiblePackages';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export type FrontendFilterProps = {
  oemName?: string;
  envName?: string;
  telematicsProgramId?: string;
  eligiblePackages?: EligiblePackageInfo[];
  vehicleType?: string;
  capableProducts?: string[];
  capableServices?: string[];
  carMake?: string;
  promoCodePackages?: EligiblePackageInfo[];
  campaignCode?: string;
  eligibleSubscriberPromoPackages?: EligiblePackageInfo[];
  engineType?: string;
};

export interface PortalFilterRule extends ContentfulObject {
  telematicsProgramIds?: string[];
  vehicleTypes?: string[];
  carMakes?: string[];
  capableProducts?: string[];
  capableServices?: string[];
  offerDetails?: string[];
  promoOfferDetails?: string[];
  campaignCodes?: string[];
  engineType?: string[];
}

export interface PortalFilter extends ContentfulObject {
  fallbackValue?: FilterableObject[];
  content: FilterableObject[];
  filterRules?: PortalFilterRule[];
}

export type ContentfulObject = {
  name: string;
  componentType?: string;
  tags?: string[];
  envTags?: string[];
};

export type FilterableObject = ContentfulObject | PortalFilter | null | undefined;

const valueIsNotInTheList = (valuesList?: string[], value?: string | string[]) => {
  if (!valuesList?.length) {
    return false;
  }

  if (!value) {
    return true;
  }

  // if we have multiple values to compare
  if (Array.isArray(value)) {
    return !valuesList.some((member) =>
      value.some((valueMember) => member.toLowerCase() === valueMember.toLowerCase()),
    );
  }
  // excluded if not explicitly listed, matching case-insensitively
  return !valuesList.some((member) => member.toLowerCase() === value.toLowerCase());
};

const filterByTag = (obj?: ContentfulObject, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.tags, props?.oemName);
};

const filterByEnv = (obj?: ContentfulObject, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.envTags, props?.envName);
};

const filterByTelematics = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.telematicsProgramIds, props?.telematicsProgramId);
};

const filterByVehicleType = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.vehicleTypes, props?.vehicleType);
};

const filterByCarMake = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.carMakes, props?.carMake);
};

const filterByCapableProducts = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.capableProducts, props?.capableProducts);
};

const filterByCapableServices = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.capableServices, props?.capableServices);
};

const filterByCampaignCode = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.campaignCodes, props?.campaignCode);
};

const filterByEligiblePackages = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  if (!obj?.offerDetails?.length) return false;
  const composedPackages = [...props?.eligiblePackages, ...props?.eligibleSubscriberPromoPackages];

  let composedOfferKeys: string[] = [];
  for (const pkg of composedPackages) {
    for (const variant of pkg.variants) {
      for (const discount of variant.discounts) {
        const offerDetailKeys = buildOfferDetailKeys(variant, discount, pkg.packageName);
        composedOfferKeys = [...composedOfferKeys, ...offerDetailKeys];
      }
    }
  }
  const uniqueOfferKeys = Array.from(new Set(composedOfferKeys));

  return uniqueOfferKeys.every((offerKey) => valueIsNotInTheList(obj?.offerDetails, offerKey));
};

const filterByPromoOfferDetails = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  if (!obj?.promoOfferDetails?.length) {
    return false;
  }

  return props?.promoCodePackages?.every((pkg) =>
    pkg.variants.every((variant) => {
      const discount = variant.discounts?.[0];
      if (discount) {
        return valueIsNotInTheList(
          obj?.promoOfferDetails,
          buildOfferDetailKeys(variant, discount, pkg.packageName)?.[1],
        );
      }
      return true;
    }),
  );
};

const filterByEngineType = (obj?: PortalFilterRule, props?: FrontendFilterProps) =>
  valueIsNotInTheList(obj?.engineType, props?.engineType);

export const globalFilterRules = [filterByTag, filterByEnv];
export const portalFilterRules = [
  filterByTag,
  filterByTelematics,
  filterByVehicleType,
  filterByCarMake,
  filterByCapableProducts,
  filterByCapableServices,
  filterByEligiblePackages,
  filterByPromoOfferDetails,
  filterByCampaignCode,
  filterByEngineType,
];

export const shouldFilterObject = (currentObject: PortalFilter, props: FrontendFilterProps): boolean => {
  const shouldBeFilteredByTagsOrEnv = globalFilterRules.some((filter) => filter(currentObject, props));
  if (shouldBeFilteredByTagsOrEnv) return true;

  if (currentObject?.filterRules) {
    //checking if at least one filterRule among all filter rules returns true
    const shouldBeFiltered = currentObject.filterRules.some((filterRule) =>
      portalFilterRules.some((filter) => filter(filterRule, props)),
    );
    if (shouldBeFiltered) return true;
  }

  return false;
};

export const useFilterRulesProps = () => {
  const selected = useSelector(
    ({ vehicleReducer, accountReducer, uiReducer }: RootState) => ({
      telematicsProgramId: vehicleReducer.vehicle?.telematicsProgramId,
      vehicleType: vehicleReducer.vehicle?.type,
      carMake: vehicleReducer.vehicle?.make,
      capableProducts: accountReducer.capableServices?.data?.map(
        (product: CapableServiceDetails) => product.productName,
      ),
      capableServices: accountReducer.capableServices?.data
        ?.map(({ services }) => services)
        .flat()
        .map((service: VehicleServiceDetails) => service.vehicleServiceName),
      campaignCode: uiReducer?.campaignCode,
      engineType: vehicleReducer.vehicle?.engineType,
    }),
    isEqual,
  );

  const {
    data: { eligiblePackages },
  } = useEligiblePackages();

  const {
    data: { eligiblePackages: eligibleSubscriberPromoPackages },
  } = useEligiblePackages(SalesChannel.SubscriberPromotion);

  return { ...selected, eligiblePackages, eligibleSubscriberPromoPackages };
};
