import { createContext } from 'react';

type Dictionary = { [key: string]: string | Dictionary };

export interface BuildedLabels {
  [key: string]: string | Dictionary;
}

const LabelContext = createContext<BuildedLabels>({});

export default LabelContext;
