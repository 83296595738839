import React from 'react';
import { SubscribedPackageInfo, AnySubscribedPackage } from '../../Types';
import { PackageHeader } from './styles';
import { SubscribedPackage, SubscribedPackageProps } from './SubscribedPackage';
import { ComponentRoutes } from '../../../Router/types';

export interface AnyPackage {
  active?: boolean;
  amountWithoutTax?: number;
}

export type filterPackagesType = (value: AnyPackage, index: number, array: AnyPackage[]) => unknown;

type SubscribedPackagesProps = {
  packages: SubscribedPackageInfo[];
  isPackageToShow: (pkg: AnySubscribedPackage) => boolean;
  currentSubscriptionLabel: string;
  location: string;
  filterPackages?: filterPackagesType;
} & Omit<SubscribedPackageProps, 'subscribedPackage'>;

export const SubscribedPackages: React.FC<SubscribedPackagesProps> = ({
  packages,
  location,
  currentSubscriptionLabel,
  isPackageToShow,
  filterPackages = (p) => p.active || p.amountWithoutTax < 0,
  ...rest
}) => {
  return (
    <div>
      {packages?.length > 0 && (
        <div>
          {location === ComponentRoutes.managePaidPackages && <PackageHeader>{currentSubscriptionLabel}</PackageHeader>}
          {packages
            .filter((anyPackage: AnySubscribedPackage) => isPackageToShow(anyPackage))
            .filter(filterPackages)
            .sort((p1, p2) => p2.amountWithoutTax - p1.amountWithoutTax)
            .map(
              (pkg) =>
                pkg.variant && (
                  <SubscribedPackage location={location} key={pkg.variant.id} subscribedPackage={pkg} {...rest} />
                ),
            )}
        </div>
      )}
    </div>
  );
};
