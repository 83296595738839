import { TokenTypes } from '@api/services/StoreService';
import { RootState } from '@app/reducers';
import { useConfig } from '@components/ConfigProvider';
import { reconcileOrder } from '@cv/portal-cps-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCookie } from '@utils/cookies';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useAccount from './useAccount';
import useVehicle from './useVehicle';

export const useReconcileSubscribedPackages = () => {
  const queryClient = useQueryClient();
  const { data: account } = useAccount();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const userId = account?._id;
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId || '');

  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  const { data: vehicle } = useVehicle();
  const vin = vehicle?.vin;

  const { mutateAsync: reconcileSubscribedPackagesMutation } = useMutation({
    mutationKey: ['reconcileSubscribedPackages'],
    mutationFn: async () => {
      const response = await reconcileOrder({
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
          'CV-Correlation-Id': `CWP-${uuidv4()}`,
        },
        environment,
        data: { action: 'ADD_DEFAULT', userId, vin } as any,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscribedPackages'] });
      queryClient.invalidateQueries({ queryKey: ['eligiblePackages'] });
    }
  });

  return {
    reconcileSubscribedPackages: reconcileSubscribedPackagesMutation,
  };
};

export default useReconcileSubscribedPackages;
