import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { CapablePackagesResponse } from '@customTypes/subscription';
import { getCookie } from '@utils/cookies';
import isPackageToShow from '@utils/isPackageToShow';
import { RootState } from '@app/reducers';
import { CapableServices } from '@api/types';
import { TokenTypes } from '@api/services/StoreService';
import { formatResponse } from '@api/requests/subscription/fetchSubscribedPackages';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import useVehicle from './useVehicle';
import { getSubscribedPackages } from '@cv/portal-cps-lib/subscription';
import { useConfig } from '@components/ConfigProvider';
import { DefaultEligiblePackagesResponse, fetchDefaultEligiblePackages } from '@api/requests/subscription';
import { formatSubscription } from '@api/formatters';
import useAccount from './useAccount';

type HookOptions = {
  excludeDefaultPkgs?: boolean;
};

const initialData: CapablePackagesResponse = {
  subscribedPackages: [],
  vin: '',
  vehicleId: '',
};

export const useSubscribedPackages = ({ excludeDefaultPkgs = true }: HookOptions = {}) => {
  const { data: account } = useAccount();
  const userId = account?._id;
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const tenantId = useSelector(({ accountReducer }: RootState) => accountReducer?.tenantId || '');

  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  const { data: vehicle } = useVehicle();
  const vehicleId = vehicle?.vehicleId;
  const servicesNames = vehicle?.capableServices?.map((service) => service.vehicleServiceName);

  return useQuery({
    enabled: !!(vehicleId && userId),
    queryKey: ['subscription', vehicleId, servicesNames],
    queryFn: async () => {
      const request = {
        data: { userId, vehicleId },
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
        },
      };

      let defaultPkgs: DefaultEligiblePackagesResponse | undefined;

      if (!excludeDefaultPkgs) {
        defaultPkgs = await fetchDefaultEligiblePackages({
          accessToken,
          tenantId,
          vehicleId,
          userId,
          environment,
          locale,
        });
      }

      const response = await getSubscribedPackages(request)
        .then((response) => formatSubscription(response.data, defaultPkgs?.data))
        .then((response) => formatResponse({ response, capableServices: vehicle?.capableServices }));
      return response;
    },
    select: ({ vin, vehicleId, services, subscribedPackages }) => {
      return {
        vin,
        vehicleId,
        activeType: services?.some(
          (subscribed) =>
            subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
        ),
        subscribedServiceIds: uniq(
          subscribedPackages
            ?.filter((subscribedPackage) => isPackageToShow(subscribedPackage as SubscriptionPackage))
            .flatMap((subscribedPackage) => {
              const serviceIds = subscribedPackage.serviceIds;
              const productsServiceIds = subscribedPackage.products.flatMap((product) => product.serviceIds);
              return [...serviceIds, ...productsServiceIds];
            }),
        ),
        packages: subscribedPackages || [],
        subscribedPackages: subscribedPackages || [],
      } as CapableServices;
    },
    placeholderData: initialData,
  });
};

export default useSubscribedPackages;
