import { OfferType, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';

export const isSubscribedPaid = (sPkg: SubscribedPackage) => {
  const PAID_PKG_OFFER_TYPES = [OfferType.Base_discount, OfferType.Promotional];
  return (
    sPkg.packageType === PackageType.Regular &&
    (!sPkg?.discounts?.length || sPkg.discounts?.filter((d) => PAID_PKG_OFFER_TYPES.includes(d.offerType)).length > 0)
  );
};
