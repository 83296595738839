import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import LanguagePicker from '@components/LanguagePicker/';
import HeaderService from './HeaderService';
import HeaderUser from './HeaderUser';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderMenuBottomNav from './HeaderMenuBottomNav';
import Navigation from '../Navigation';
import styles from './Header.module.css';
import StolenVehicleLocator from './StolenVehicleLocator';
import VehicleSwitcher, { VehicleSwitcherContent } from '@components/VehicleSwitcher';
import { useSelector } from 'react-redux';
import { ACTIVE, STOLEN_VEHICLE } from './constant';
import { HeaderOemBrandLogoType } from './HeaderOemBrandLogo';
import { ISxmKey, useApi } from '@api';
import { getSearchParam } from '@utils/url';
import WarningBannerConnector, { WarningBannerProps } from './WarningBanner';
import SubscriptionInformation from './SubscriptionInformation';
import { SubscriptionInformationProps } from './SubscriptionInformation/SubscriptionInformation';
import ResponsiveLogo from './ResponsiveLogo';
import { useLocation } from 'react-router-dom';
import { LanguagePreference } from '@components/LanguagePicker/LanguagePicker';
import urlSearchParams from '@utils/urlSearchParams';
import { useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigation } from '@components/Navigation';
import useIsAuthorised from '@hooks/useIsAuthorised';

export type HeaderProps = {
  content: {
    name?: string;
    tags?: string[];
    logo?: object;
    url?: string;
    service?: object;
    mobileMenuNavigation?: object;
    userMenuNavigation?: object;
    userPrefix?: string;
    navigation?: object;
    logoutLabel?: string;
    loginLabel?: string;
    pagesToDisplayLogout?: string[];
    pagesToHideDropdown: string[];
    pagesToHideTcLinkInUserDropDown?: string[];
    pagesToHideVehicleSwitcher?: string[];
    pagesToHideSubscriptionInformation?: string[];
    stolenVehicleLocator: {
      header: string;
      firstText: string;
      secondText: string;
      thirdText: string;
      url: string;
      serviceName: string;
    };
    languagePreference: LanguagePreference;
    bottomBarTheme?: string;
    oemBrandLogo: Array<HeaderOemBrandLogoType>;
    warningBanner?: WarningBannerProps;
    subscriptionInformation?: SubscriptionInformationProps;
  };
  withNavigation?: boolean;
  withUser?: boolean;
};

export type HeaderContent = HeaderProps['content'];

function Header({ content, withNavigation, withUser }: HeaderProps) {
  const { paths } = useNavigation();
  const isAuthorised = useIsAuthorised();
  const hideNavigation = getSearchParam(window.location.search, 'hideNavigation');
  const { sxmKeyDetails } = urlSearchParams.getAll<{
    sxmKeyDetails: ISxmKey;
  }>();
  const api = useApi();
  const {
    service,
    mobileMenuNavigation,
    userMenuNavigation,
    userPrefix,
    stolenVehicleLocator,
    bottomBarTheme,
    oemBrandLogo,
    warningBanner,
    subscriptionInformation,
    pagesToHideVehicleSwitcher,
    pagesToHideSubscriptionInformation,
  } = content;

  const isAuthenticated = api.storeService.getAccessToken() && api.storeService.getRefreshToken();
  const { svl } = useSelector(({ stolenVehicleReducer }) => stolenVehicleReducer);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [dropDownContainer, setDropDownContainer] = useState<HTMLDivElement | undefined>(undefined);
  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const displayMobileMenu = !!(
    (withNavigation || withUser) &&
    (content?.navigation || mobileMenuNavigation || userMenuNavigation)
  );

  const hasNavItems = paths.some(
    ({ url, hiddenInNavigation, authorised }) => url && !hiddenInNavigation && isAuthorised === !!authorised,
  );

  const theme = useTheme();

  useEffect(() => {
    if (headerRef?.current) {
      setDropDownContainer(headerRef.current);
    }
  }, [headerRef]);

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const renderNavItems = (style: string) => {
    if (content && content['navigation']) {
      return (
        <Navigation
          name="mainNavigation"
          className={styles[style]}
          {...content['navigation']}
          isNavigationMachine
          style={{
            color: theme.palette.getContrastText(theme.palette.background.header),
          }}
        />
      );
    }
  };
  const renderHeaderUser = () => (
    <HeaderUser
      className={styles['Header-user']}
      userPrefix={userPrefix}
      navigation={userMenuNavigation}
      logoutLabel={content?.logoutLabel}
      loginLabel={content?.loginLabel}
      pagesToDisplayLogout={content?.pagesToDisplayLogout}
      pagesToHideDropdown={content.pagesToHideDropdown}
      pagesToHideTcLinkInUserDropDown={content?.pagesToHideTcLinkInUserDropDown}
      theme={bottomBarTheme}
    />
  );

  const renderSvlHeader = () => {
    if (svl && svl.status === ACTIVE && svl.type === STOLEN_VEHICLE) {
      return <StolenVehicleLocator stolenVehicleLocator={stolenVehicleLocator} />;
    }
  };

  if (hideNavigation) {
    return null;
  }

  return (
    <>
      <header
        className={clsx(styles['Header-container'], { [styles['Header--with-nav']]: withNavigation })}
        style={{
          backgroundColor: theme.palette.background.header,
          zIndex: 1301, // * because 1300 is z-index of MUI modal backdrop
        }}
      >
        <div className={styles['Header-wrapper']}>
          <ResponsiveLogo logos={oemBrandLogo} pagesWhenClick={content?.pagesToHideTcLinkInUserDropDown} />
          <div
            className={clsx(styles['Header-inner'], styles['Header-upper'])}
            style={{ color: theme.palette.text.navigation }}
          >
            {withNavigation && renderNavItems('Header-main-navigation')}
          </div>
          {service && <HeaderService imageData={(service as [{ asset?: object }])[0]?.asset ?? service} />}
          {displayMobileMenu && hasNavItems && (
            <div className={clsx(styles['Header-mobile-nav'], styles['Header-upper'])}>
              {(withNavigation || withUser) && (
                <MenuIcon
                  onClick={toggleMobileMenu}
                  sx={{
                    color: theme.palette.text.headerIcons,
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div
          className={styles['Header-lower']}
          ref={headerRef}
          style={{
            backgroundColor: theme.palette.background.subHeader,
            color: theme.palette.getContrastText(theme.palette.background.subHeader),
          }}
        >
          <div className={styles['Header-lower-content-left']}>
            {isAuthenticated && (
              <>
                <VehicleSwitcher pagesToHideVehicleSwitcher={pagesToHideVehicleSwitcher}>
                  <VehicleSwitcherContent
                    svlService={stolenVehicleLocator?.serviceName}
                    theme={bottomBarTheme}
                    className={styles['Header-content-left']}
                  />
                </VehicleSwitcher>

                {warningBanner && (
                  <WarningBannerConnector
                    {...warningBanner}
                    theme={bottomBarTheme}
                    dropDownContainer={dropDownContainer}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles['Header-lower-content-right']}>
            {!pagesToHideSubscriptionInformation?.includes(location?.pathname) &&
              isAuthenticated &&
              subscriptionInformation && (
                <SubscriptionInformation
                  {...subscriptionInformation}
                  theme={bottomBarTheme}
                  dropDownContainer={dropDownContainer}
                />
              )}
            {(isAuthenticated || sxmKeyDetails) && content.languagePreference && (
              <LanguagePicker
                languagePreference={content.languagePreference}
                theme={bottomBarTheme}
                className={styles['Header-language-picker']}
              />
            )}
            {withUser && renderHeaderUser()}
          </div>
        </div>
        {displayMobileMenu && (
          <HeaderMobileMenu onClose={() => setIsMobileMenuVisible(false)} isVisible={isMobileMenuVisible}>
            {withUser && renderHeaderUser()}
            {withNavigation && renderNavItems('Header-main-mobile-navigation')}
            {withNavigation && mobileMenuNavigation && <HeaderMenuBottomNav navigation={mobileMenuNavigation} />}
          </HeaderMobileMenu>
        )}
      </header>
      {renderSvlHeader()}
    </>
  );
}

Header.defaultProps = {
  content: {
    logo: null,
    url: '/',
    userPrefix: null,
    service: null,
    navigation: [],
    mobileMenuNavigation: null,
    userMenuNavigation: null,
  },
};

export default Header;
