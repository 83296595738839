import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import useLabels from '@hooks/useLabels';
import useSetPreferences from '@redux/hooks/preferences';
import { ContentfulLabelType, findDictionary, LabelDictionary, ServerLabel } from '@utils/labels';
import { PackageFlag } from '@customTypes/package-flags';
import { omitBy, isNil } from 'lodash';

type Favicon = {
  favicon: ContentfulFile;
  name: string;
  size: string;
};

export type GlobalPreferencesContent = {
  pageTitle: string;
  faviconList: Favicon[];
  keywords: string;
  pageDescription: string;
  sharedProperties?: ContentfulLabelType[];
  cookiesCloseIcon: ContentfulFile;
  isSinglePaymentMethod?: boolean;
  goToManageToSubscribe?: boolean;
  shouldMatchTrialWithCCRequired?: boolean;
  isAuthCodeFunctionality?: boolean;
  hideDownloadAppOnConfirmation?: boolean;
  otpTimeout?: number;
  packageFlags?: PackageFlag[];
  showDatesOnPromoPage?: boolean;
  marketingOptInEnabled?: boolean;
};

type GlobalPreferencesProps = {
  content: GlobalPreferencesContent;
  labelsFilter?: BrandNamesFilterGroup;
};

// Create static data from contentful to use it outside of react world.
const preferences: Record<string, string> = {};

export function get(key: string): string | null;
export function get<T>(key: string): T;
export function get<T>(key: string, defaultValue: T): T;
export function get<T>(key: string, defaultValue: T | null = null): string | T | null {
  if (key in preferences) {
    return preferences[key] as T;
  }

  return defaultValue;
}

const packageFlagsNames = (f?: PackageFlag[]) => f?.map(({ name }) => name) || [];

const GlobalPreferences = ({
  content: {
    pageTitle,
    keywords,
    pageDescription,
    faviconList,
    isSinglePaymentMethod = false,
    goToManageToSubscribe = false,
    shouldMatchTrialWithCCRequired = false,
    isAuthCodeFunctionality = false,
    hideDownloadAppOnConfirmation = false,
    sharedProperties,
    packageFlags,
    otpTimeout,
    showDatesOnPromoPage = false,
    marketingOptInEnabled = false,
  },
  labelsFilter,
}: GlobalPreferencesProps): JSX.Element => {
  const setPreferences = useSetPreferences();

  const combinedLabels = useMemo(
    () =>
      sharedProperties?.reduce(
        (acc: ServerLabel[], labelsDictionary: LabelDictionary) => [...acc, ...labelsDictionary.content],
        [],
      ) || [],
    [sharedProperties],
  );
  const labels = useLabels(combinedLabels, labelsFilter).getAllPrimaries();
  const memoLabels = useMemo(() => labels, Object.keys(labels));

  const phoneTypeShortcutsLabels = useLabels(findDictionary(sharedProperties, 'phoneTypeShortcuts')).getAllPrimaries();
  const memoPhoneLabels = useMemo(() => phoneTypeShortcutsLabels, Object.keys(phoneTypeShortcutsLabels));
  const memoPackageFlags = useMemo(() => packageFlags, packageFlagsNames(packageFlags));

  useEffect(() => {
    const allPreferences = Object.assign(
      preferences,
      omitBy(
        {
          ...memoLabels,
          isSinglePaymentMethod,
          goToManageToSubscribe,
          shouldMatchTrialWithCCRequired,
          isAuthCodeFunctionality,
          hideDownloadAppOnConfirmation,
          otpTimeout,
          phoneTypeShortcutsLabels: memoPhoneLabels,
          packageFlags: memoPackageFlags,
          showDatesOnPromoPage,
          marketingOptInEnabled,
        },
        isNil,
      ),
    );
    setPreferences(allPreferences);
  }, [
    memoLabels,
    isSinglePaymentMethod,
    goToManageToSubscribe,
    shouldMatchTrialWithCCRequired,
    isAuthCodeFunctionality,
    hideDownloadAppOnConfirmation,
    otpTimeout,
    memoPhoneLabels,
    setPreferences,
    memoPackageFlags,
    showDatesOnPromoPage,
    marketingOptInEnabled,
  ]);

  const addFavicons = () => {
    return faviconList.map((item, index) => (
      <link
        rel="icon"
        type={item?.favicon?.file?.contentType}
        href={`https:${item?.favicon.file?.url}`}
        sizes={item.size}
        key={`meta-item-${index}`}
      />
    ));
  };

  return (
    <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle}>
      {addFavicons()}
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default GlobalPreferences;
