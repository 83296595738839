import {
  SubscribedPackage,
  TaxLineItem,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';

export const getTaxRate = (sPkgs: SubscribedPackage[], taxName: string) => {
  if (!sPkgs?.length) return 0.0;
  const targetIndex = sPkgs.findIndex((pkg) => pkg.taxLineItems?.length && hasSpecifiedTax(pkg.taxLineItems, taxName));
  return targetIndex > -1 ? sPkgs[targetIndex].taxLineItems[0].rate : 0.0;
};

export const getTaxRateFromTaxLineItems = (taxLineItems: TaxLineItem[] | null, taxName: string) => {
  return taxLineItems?.length && hasSpecifiedTax(taxLineItems, taxName) ? Number(taxLineItems[0].rate) : 0.0;
};

export const hasSpecifiedTax = (taxLineItems: TaxLineItem[], taxName: string) => {
  return taxLineItems?.length ? taxLineItems.some((tax) => tax.name === taxName) : false;
};

export const getTaxFromTaxRate: (taxRate: number, price: number) => number = (taxRate: number, price: number) => {
  const amountWithoutTax = Number(price);
  const taxAmount = Number(taxRate * amountWithoutTax);
  return Number(Math.round((taxAmount + Number.EPSILON) * 100) / 100);
};
