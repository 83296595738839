import React, { useEffect } from 'react';

import { useInterpret, useSelector as useXstateSelector } from '@xstate/react';
import {
  CancelSubscriptionMachineContext,
  EVENTS,
  cancelSubscriptionMachine,
  initialContext,
} from './CancelSubscriptionStateMachine';
import { InterpreterFrom } from 'xstate';
import { merge } from 'lodash';
import { useVehicleSelector } from '@redux/selectors/vehicle';
import { IVehicle } from '@redux/actions';
import Cancellation from './Cancellation';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import useEligiblePackages from '@api/queries/useEligiblePackages';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import CancelLabelsContainer from './CancelLabelsContext';
import useOrders from '@api/queries/useOrders';
import { useHistory } from 'react-router';
import { useConfig } from '@components/ConfigProvider';
import { useFeatureFlags } from '@components/FeatureFlags';

export const CancelMachineContext = React.createContext<{
  cancelMachine: InterpreterFrom<typeof cancelSubscriptionMachine>;
}>(null);
export const CancelMachineProvider: React.FC<{
  children: React.ReactNode;
  selectedVehicle?: IVehicle;
  context?: CancelSubscriptionMachineContext;
}> = ({ children, selectedVehicle }) => {
  const history = useHistory();
  const config = useConfig();
  const cancellationEligiblePackageChannel = config.get('CANCELLATION_ELIGIBLE_PACKAGE_CHANNEL') as SalesChannel;
  const { createOrder, previewOrder } = useOrders({ salesChannel: cancellationEligiblePackageChannel });
  const featureFlags = useFeatureFlags();

  const cancelMachine = useInterpret(
    cancelSubscriptionMachine.withContext(
      merge(initialContext, { selectedVehicle, createOrder, previewOrder, history, featureFlags }),
    ),
  );
  const {
    data: { packages: subscribedPackages = [] },
    isFetched,
  } = useSubscribedPackages();
  const {
    data: { eligiblePackages },
    isError: eligiblePackagesError,
  } = useEligiblePackages();
  const {
    data: { eligiblePackages: subscriberDiscountEligiblePackages },
    isError: subscriberDiscountError,
  } = useEligiblePackages(cancellationEligiblePackageChannel);

  useEffect(() => {
    const _subscribedPackages = subscribedPackages.filter((pkg) => !pkg.cancelReceiveDate);

    cancelMachine.send(EVENTS.SET_SUBSCRIBED_PACKAGES, { subscribedPackages: _subscribedPackages, isFetched });
  }, [cancelMachine, history, isFetched, subscribedPackages]);

  useEffect(() => {
    if (eligiblePackagesError || !eligiblePackages) {
      cancelMachine.send(EVENTS.SET_ELIGIBLE_PACKAGES, { eligiblePackages: [] });
      return;
    }
    cancelMachine.send(EVENTS.SET_ELIGIBLE_PACKAGES, { eligiblePackages });
  }, [cancelMachine, eligiblePackages, eligiblePackagesError]);

  useEffect(() => {
    if (subscriberDiscountError || !subscriberDiscountEligiblePackages) {
      cancelMachine.send(EVENTS.SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES, { subscriberDiscountEligiblePackages: [] });
      return;
    }
    cancelMachine.send(EVENTS.SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES, { subscriberDiscountEligiblePackages });
  }, [cancelMachine, subscriberDiscountEligiblePackages, subscriberDiscountError]);

  return <CancelMachineContext.Provider value={{ cancelMachine }}>{children}</CancelMachineContext.Provider>;
};

export const useCancelMachine = () => {
  const { cancelMachine } = React.useContext(CancelMachineContext);
  return cancelMachine;
};

export const useCancelMachineContext = () => {
  const cancelMachine = useCancelMachine();
  return useXstateSelector(cancelMachine, (state) => state.context);
};

export const CancelSubscription = (props: CancellationProps) => {
  const selectedVehicle = useVehicleSelector();
  return (
    <CancelMachineProvider selectedVehicle={selectedVehicle}>
      <CancelLabelsContainer contentSections={props.contentSections}>
        <Cancellation {...props} />
      </CancelLabelsContainer>
    </CancelMachineProvider>
  );
};

export default CancelSubscription;
