import React, { useEffect, useMemo, useState } from 'react';

import { AggregatedFlowContext } from '@components-lib/Router/flows/componentFlow/Types';
import {
  EventDataBuilder,
  EventType,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ContentMap } from '../..';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../../Types';
import { getPaidPackage } from '../../utils';
import ViewPackages, { ViewPackagesProps } from './ViewPackages';
import { extractVariants } from './utils';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { findIndex } from 'lodash';
import { SubscriptionProps } from '../..';
import { deDupePackages } from '../../utils';
import { differenceWith, isEqual, sortBy } from 'lodash';
import { getPackagesWithTax } from '@app/components-lib/services';
import { useLabelContext } from '@components/LabelContext';
import { SubscriptionLabels } from '@components/SubscriptionWrapper/SubscriptionLabels';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}
function PackagesListTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`packages-tabpanel-${index}`}
      aria-labelledby={`packages-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export type PaidPackagesProps = {
  packages: EligiblePackageInfo[];
  removePaidPackages: () => void;
  termsLabel: ContentMap;
  tenantId: string;
  subscribedPackages: SubscribedPackageInfo[];
  packageSubscriptions: PackageSubscription[];
  flowMachineContext: AggregatedFlowContext;
} & Omit<ViewPackagesProps, 'packages'>;

const applyOrder = (ePackages: Array<EligiblePackageInfo>, packageOrder: string[] = []) => {
  const orderedPackages = packageOrder
    .map((name) => ePackages.find((pkg) => pkg.marketingName === name && !pkg.bundle))
    .filter(Boolean);
  const bundlePackages = ePackages.filter((pkg) => pkg.bundle);
  const remainingPackages = differenceWith(ePackages, [...orderedPackages, ...bundlePackages], isEqual);

  const sortedPackages = sortBy(remainingPackages, ['tier', 'variant.actualPrice']);

  return [...bundlePackages, ...orderedPackages, ...sortedPackages];
};

export const sortAndDedupe = (
  ePackages: Array<EligiblePackageInfo>,
  subscriptionProps: SubscriptionProps,
  subscribedPackages: SubscribedPackageInfo[],
  shouldIncludeTax: boolean,
  packageOrder: string[],
) => {
  const deDupedPackages = deDupePackages(ePackages, OfferType.Promotional);
  const sortedPackages = applyOrder(deDupedPackages, packageOrder);

  return getPackagesWithTax(sortedPackages, subscriptionProps, subscribedPackages, shouldIncludeTax);
};

const PaidPackages = ({
  packages,
  tenantId,
  termsLabel,
  subscribedPackages,
  packageSubscriptions,
  removePaidPackages,
  flowMachineContext,
  ...viewPackagesProps
}: PaidPackagesProps) => {
  // Extract package variants
  const variants = useMemo(
    () => extractVariants(subscribedPackages, packages, tenantId),
    [subscribedPackages, packages, tenantId],
  );
  const allTerms = Object.keys(variants);

  const [isLoading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const showTabConfigFeature = flowMachineContext?.content?.packagesWebContent?.featurePackagesTabConfig;
  const isMobileView = useMediaQuery(theme.breakpoints.up('md'));

  const selectedVariantIndex = () => {
    if (showTabConfigFeature !== undefined && variants[showTabConfigFeature] !== undefined) {
      return showTabConfigFeature;
    }
    const current = getPaidPackage(packageSubscriptions, subscribedPackages);
    const isTermInKeys = !!current && allTerms.includes(String(current.variant.initialTerm));
    if (isTermInKeys) {
      return String(allTerms[findIndex(allTerms, (key) => key === String(current.variant.initialTerm))]);
    }
    return String(allTerms[allTerms.length - 1]);
  };

  const [selectedTab, setSelectedTab] = useState<string>(selectedVariantIndex());
  const [tabPackages, setTabPackages] = useState<{ [key: string]: EligiblePackageInfo[] }>({});

  const { packageOrder } = useLabelContext<SubscriptionLabels>();

  useEffect(() => {
    if (!tabPackages[selectedTab]) {
      formatPackages();
    }

    async function formatPackages() {
      setLoading(true);
      const packagesOrder = Object.keys(packageOrder);
      const ePackages = await sortAndDedupe(
        variants[selectedTab],
        viewPackagesProps.subscriptionProps,
        subscribedPackages,
        viewPackagesProps.commonWebContent.shouldIncludeTax,
        packagesOrder,
      );
      setTabPackages((prevTabPackages) => ({ ...prevTabPackages, [selectedTab]: ePackages }));
      setLoading(false);
    }
  }, [selectedTab, variants, viewPackagesProps, subscribedPackages, packageOrder, tabPackages, setLoading]);

  if (Object.keys(variants).length === 0) {
    return null;
  }

  const fireListingEvent = () => {
    openAnalyticsBracket(flowMachineContext);
    sendAnalyticsEvent(new EventDataBuilder(EventType.ProductListingDisplayedEvent).withArgs(variants[selectedTab]));
    closeAnalyticsBracket();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(fireListingEvent, [selectedTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    // clear selections
    removePaidPackages();
    // select tab
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="packages variants tabs"
        variant={isMobileView ? 'standard' : 'fullWidth'}
      >
        {allTerms.map((termTab) => (
          <Tab label={termsLabel[termTab]} value={termTab} key={termTab} disableFocusRipple />
        ))}
      </Tabs>
      <Divider />
      {allTerms.map((termTab) => (
        <PackagesListTabPanel value={selectedTab} index={termTab} key={termTab}>
          <ViewPackages
            {...viewPackagesProps}
            subscribedPackages={subscribedPackages}
            packageSubscriptions={packageSubscriptions}
            eligiblePackages={tabPackages[selectedTab]}
            isLoading={isLoading}
          />
        </PackagesListTabPanel>
      ))}
    </Container>
  );
};

export default PaidPackages;
