import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LabelObject } from '@utils/labels';
import { RootState } from '@app/reducers';

const useFilterByModels = (labels: LabelObject): LabelObject => {
  const { model } = useSelector(({ vehicleReducer }: RootState) => vehicleReducer?.vehicle);
  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries(labels).filter(
          ([, { models }]) =>
            !models ||
            (!!models.include && models.include.includes(model)) ||
            (!!models.avoid && !models.avoid.includes(model)),
        ),
      ),
    [labels, model],
  );
};

export default useFilterByModels;
