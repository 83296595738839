import React, { ReactNode } from 'react';
import { LineSeparator, Title } from '../../styled-components/globalStyles';
import { Discount, Pricing, Section } from './styles';
import { SubscribedPackages } from '../Packages';
import { OfferDetails } from '../Discount';
import { DiscountInfo, OrderSummaryInfo } from '../Types';
import { getPromoCodePackage, TAX_NAMES } from '../utils';
import { SubscriptionProps, WebContent } from '../Subscription';
import { filterPackagesType } from '../Packages/SubscribedPackage/SubscribedPackages';
import { useFeatureFlags } from '@components/FeatureFlags';
import { getTaxRateFromTaxLineItems, getTaxFromTaxRate } from '@utils/tax';

interface OrderSummaryProps {
  orderSummaryInfo: OrderSummaryInfo;
  children: ReactNode;
  discountInfo?: DiscountInfo;
  location: string;
  subscriptionProps: SubscriptionProps;
  content: WebContent;
  filterPackages?: filterPackagesType;
}

export const OrderSummary = ({
  orderSummaryInfo,
  children,
  discountInfo,
  location,
  subscriptionProps,
  content,
  filterPackages,
}: OrderSummaryProps) => {
  const featureFlags = useFeatureFlags();
  const { customerCareCancelPackageNumber, expireRenewText, isPackageToShow } = subscriptionProps;
  const {
    orderSummaryLabel,
    commonWebContent,
    packagesWebContent,
    promoPackagesWebContent: { discount: discountWebContent },
    assets,
  } = content;
  const { currencyLabel } = commonWebContent;

  if (!orderSummaryInfo) {
    return null;
  }

  const { packageName, variant, discount, totalDiscountAmount, taxLineItems } = getPromoCodePackage(
    orderSummaryInfo?.packages,
    discountInfo?.promoCode,
  );
  const taxRate = getTaxRateFromTaxLineItems(taxLineItems, TAX_NAMES.VAT_TAX);
  const totalDiscountAmountWithTax = taxRate
    ? totalDiscountAmount + getTaxFromTaxRate(taxRate, totalDiscountAmount)
    : 0.0;

  return (
    <Section>
      <Title>{orderSummaryLabel}</Title>
      <LineSeparator />
      <SubscribedPackages
        packages={orderSummaryInfo.packages}
        commonWebContent={commonWebContent}
        packagesWebContent={packagesWebContent}
        customerCareCancelPackageNumber={customerCareCancelPackageNumber}
        expireRenewText={expireRenewText}
        discountWebContent={discountWebContent}
        isPackageToShow={isPackageToShow}
        currentSubscriptionLabel={packagesWebContent.currentSubscriptionLabel}
        location={location}
        assets={assets}
        filterPackages={filterPackages}
      />
      {discount && (
        <>
          <Pricing>
            <Title>{discount.promoCode}</Title>
            <Discount>
              {currencyLabel}
              {featureFlags.shouldIncludeTax
                ? (-Math.abs(totalDiscountAmountWithTax)).toFixed(2)
                : (-Math.abs(totalDiscountAmount)).toFixed(2)}
            </Discount>
          </Pricing>
          {discount.description}
          <OfferDetails
            packageName={packageName}
            assets={assets}
            variant={variant}
            discount={discount}
            okButtonLabel={commonWebContent.okButtonLabel}
            discountWebContent={discountWebContent}
            customerCareCancelPackageNumber={customerCareCancelPackageNumber}
          />
          <LineSeparator />
        </>
      )}
      {children}
    </Section>
  );
};
