import React from 'react';
import useVehicle from '@api/queries/useVehicle';
import Modal from '@lib-components/NewModal/Modal';
import { Button } from '@app/components-lib';
import { Stack, Typography } from '@mui/material';
import { Can, FeatureFlagsKeys } from '@components/FeatureFlags';

interface Labels {
  serviceRequiredText: string;
  serviceRequiredHeader: string;
  serviceRequireButtonText: string;
  billingSystemBlockRedirectURL: string;
}

interface Props {
  children: React.ReactNode;
  labels: Labels;
}
const BillingsystemBlocker = ({ children, labels = {} }: Props) => {
  const { data: vehicle } = useVehicle();
  const { serviceRequiredText, serviceRequiredHeader, serviceRequireButtonText, billingSystemBlockRedirectURL } =
    labels;

  return (
    <>
      <Can featureFlag={FeatureFlagsKeys.blockVehicleByBillingSystem}>
        <Modal open={!!vehicle?.billingSystem} onClose={() => { }} title={serviceRequiredHeader}>
          <Stack gap={2} alignContent="center">
            <Typography>{serviceRequiredText}</Typography>
            <Button component={'a'} href={billingSystemBlockRedirectURL || ''}>
              {serviceRequireButtonText}
            </Button>
          </Stack>
        </Modal>
      </Can>
      {children}
    </>
  );
};

export default BillingsystemBlocker;
