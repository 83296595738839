import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { LabelObject } from '@utils/labels';
import { useTheme, useMediaQuery } from '@mui/material';
import AcceptTerms from './AcceptTerms';
import TermsAccepted from './TermsAccepted';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import useDefaultPackages from '@api/queries/useDefaultPackages';
import { LoaderBackdrop } from '@components/Loader';
import useReconcileSubscribedPackages from '@api/queries/useReconcileSubscribedPackages';
import useVehicle from '../../api/queries/useVehicle';
import { useConfig } from '@components/ConfigProvider';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';
import TermsImplicitylyAccepted from './TermsImplictlyAccepted';
import { sub } from 'date-fns';

enum Page {
  Terms = 'Terms',
  SubmitFinal = 'SubmitFinal',
  NoSubmitFinal = 'NoSubmitFinal',
}

export type DataCollectionTermsProps = {
  labels: LabelObject;
};

const DataCollectionTerms = ({ labels }: DataCollectionTermsProps) => {
  const { data: subscribedPackages, isFetched: isSubscribedFetched } = useSubscribedPackages();
  const { data: defaultEligiblePackages, isFetched: isDefaultFetched } = useDefaultPackages();
  const { reconcileSubscribedPackages } = useReconcileSubscribedPackages();
  const [page, setPage] = useState(Page.Terms);
  const history = useHistory();
  const config = useConfig();
  const isSubscribed = subscribedPackages?.subscribedPackages?.length;
  const isDefaultEmpty = !defaultEligiblePackages?.length;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { data = { registrationCountry: '' } } = useVehicle();

  useEffect(() => {
    sendAnalyticsEvent(
      new EventDataBuilder(EventType.PortalPageLoadStartedEvent).withArgs({
        customer_flow: 'ten year flow',
        pageName: location.pathname,
        oem: config.getOemName(),
        siteCountry: data?.registrationCountry,
        isMobile: isMobileView,
        vin: subscribedPackages?.vin,
        siteName: window.location.hostname,
        vehicleID: '',
        siteLanguage: '',
      }),
    );
    sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadEvent).withArgs());
  }, []);

  const handleSubmit = () => {
    setPage(Page.SubmitFinal);
    reconcileSubscribedPackages();
  };

  if (!isSubscribedFetched || !isDefaultFetched) {
    return <LoaderBackdrop />;
  }

  const defaultArr = [];
  const tenArr = [];
  const onlyDefaultArr = [];

  subscribedPackages?.subscribedPackages?.map((p, i) => {
    defaultArr.push(p?.productCode?.includes('DEFAULT'));
    tenArr.push(p?.productCode?.includes('10'));
    onlyDefaultArr.push(p?.productCode?.includes('DEFAULT') || p?.productCode?.includes('10'));
    return subscribedPackages;
  });

  if (onlyDefaultArr.includes(false)) {
    if (tenArr.includes(true) || defaultArr.includes(true)) {
      return <Redirect to="/profile" />;
    }
    return <Redirect to="/profile" />;
  }

  return (
    <>
      {page === Page.Terms && onlyDefaultArr.includes(true) && !tenArr.includes(true) && (
        <AcceptTerms onSubmit={handleSubmit} labels={labels} />
      )}
      {page === Page.SubmitFinal && <TermsAccepted labels={labels} goTo={() => history.push('/home')} />}
      {tenArr.includes(true) && !onlyDefaultArr.includes(false) && (
        <TermsImplicitylyAccepted labels={labels} goTo={() => history.push('/home')} />
      )}
    </>
  );
};

export default DataCollectionTerms;
