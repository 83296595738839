import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURFEW_ACTIONS, DIALOG_ACTIONS } from '@redux/actions/';

import { useApi } from '@api';
import { addDuration, adjustDaysOfTheWeek, formatLocalDate, mapDaysFromLang, saveCurfewAlert } from '../Forms/utils';
import ActiveView from '../ActiveView';
import AlertsCount from '../AlertsCount';
import { Button } from '@components-lib';
import CurfewForm from '../Forms/CurfewForm';
import ManageCardItem from '../ManageCardItem';
import TabHeader from '../TabHeader';
import TabInfo from '../TabInfo';

import { useAnalytics } from '@cv/webframework-react-components';
import stylesMonitoring from '../../MonitoringAlerts/MonitoringAlerts.module.css';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { CurfewResponse } from '@cv/portal-rts-lib/curfews/models';
import { Status, WeekDay as DaysEnum } from '@cv/portal-rts-lib/doors/enums';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { setLoadingStatus } from '@redux/actions/loading';
import Loader from '@components/Loader';
import transformToOptions, { Option } from '@utils/transformToOptions';
import { cloneDeep } from 'lodash';
import useToggle from '@hooks/useToggle';
import { Modal } from '@components-lib';

export interface WeekDay extends Option<DaysEnum> {
  selected: boolean;
}

const CurfewItemState = {
  ACTIVE: true,
  INACTIVE: false,
};

type TabCurfewProps = {
  alertSetLabel: string;
  alertSetPreposition: string;
  cancelButton: string;
  cancelConfirm: string;
  dayLabel: string;
  daysSelectDayEndLabel: string;
  daysSelectDayStartLabel: string;
  daysValidationErrorMessage: string;
  defaultViewButtonLabel: string;
  editViewTitle: string;
  editFormViewTitle: string;
  fieldErrorMessage: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  infoBoxContent: string;
  switchDescription: string;
  tabDescription: string;
  title: string;
  weekDays: Array<string>;
  weekLabel: string;
  createSuccessMsg: string;
  createErrorMsg: string;
  updateSuccessMsg: string;
  updateErrorMsg: string;
  deleteSuccessMsg: string;
  deleteErrorMsg: string;
  successDialogHeader: string;
  errorDialogHeader: string;
  nameErrorMsg: string;
  formNameError: string;
  defaultViewContent: string;
  iButtonAnalyticsEventName: string;
  addNewAnalyticsEventName: string;
  curfewLimitAnalyticsEventName: string;
  displayAlertInvehicleToggleOnAnalyticsEventName: string;
  displayAlertInvehicleToggleOffAnalyticsEventName: string;
  saveAnalyticsEventName: string;
  cancelAnalyticsEventName: string;
  editAnalyticsEventName: string;
  attemptsExceededMessage: string;
  atLeastOneDayOfTheWeekMsg: string;
};

function TabCurfew({
  alertSetLabel,
  alertSetPreposition,
  cancelButton,
  cancelConfirm,
  dayLabel,
  daysSelectDayEndLabel,
  daysSelectDayStartLabel,
  daysValidationErrorMessage,
  defaultViewButtonLabel,
  editViewTitle,
  editFormViewTitle,
  fieldErrorMessage,
  fieldLabel,
  fieldPlaceholder,
  infoBoxContent,
  switchDescription,
  tabDescription,
  title,
  weekDays,
  weekLabel,
  createSuccessMsg,
  createErrorMsg,
  updateSuccessMsg,
  updateErrorMsg,
  deleteSuccessMsg,
  deleteErrorMsg,
  successDialogHeader,
  errorDialogHeader,
  nameErrorMsg,
  formNameError,
  defaultViewContent,
  iButtonAnalyticsEventName,
  addNewAnalyticsEventName,
  curfewLimitAnalyticsEventName,
  displayAlertInvehicleToggleOnAnalyticsEventName,
  displayAlertInvehicleToggleOffAnalyticsEventName,
  saveAnalyticsEventName,
  cancelAnalyticsEventName,
  editAnalyticsEventName,
  attemptsExceededMessage,
  atLeastOneDayOfTheWeekMsg,
}: TabCurfewProps) {
  const { trackEvent } = useAnalytics();
  const api = useApi();
  const [view, setView] = useState('default');
  const [isLoading, setLoading] = useState(false);
  const curfew = useSelector(({ curfewReducer }) => curfewReducer.curfew);
  const dispatch = useDispatch();
  const [selectedCurfew, setSelectedCurfew] = useState(null);
  const curfewsLimit = 2;
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const [attemptsExceededDialog, setAttemptsExceededDialog] = useToggle(false);

  let curfewId = '';

  const weekDaysOptions = useMemo(
    () =>
      transformToOptions<DaysEnum, WeekDay>(weekDays).map((option) => {
        option.selected = false;
        return option;
      }),
    [weekDays, locale],
  );
  //TODO: verify that 'status' and 'inVehicleWarning' values should be updated and send in request.

  const defaultFormValues = {
    name: '',
    startTime: '10:00PM',
    endTime: '6:00AM',
    dayOfTheWeek: cloneDeep(weekDaysOptions).map((opt) => {
      opt.selected = true;
      return opt;
    }),
    status: CurfewItemState.INACTIVE,
    inVehicleWarning: false,
    allDay: false,
  };

  const mapFormInitialValues = ({ name, schedules: [schedule], inVehicleWarning }) => {
    const { duration } = schedule;
    // Caluclating end time from duration ie. start date + duration = end date
    const localStartDate = new Date(`${schedule.startDate} ${schedule.startTime}`);
    const localEndDate = addDuration(duration, localStartDate);

    // Mapping the days from api reponse to user locale
    const dayAdjustment = localStartDate.getDay() - localStartDate.getUTCDay();
    const adjustedDays = adjustDaysOfTheWeek(
      schedule.recurrenceRules[0].dayOfTheWeek,
      weekDaysOptions,
      dayAdjustment,
      locale,
    );

    return {
      name: name,
      startTime: `${formatLocalDate(localStartDate, 'h:mma')}`,
      endTime: `${formatLocalDate(localEndDate, 'h:mma')}`,
      dayOfTheWeek: adjustedDays,
      inVehicleWarning: inVehicleWarning,
      allDay: false,
    };
  };

  const getCurfews = async () => {
    setLoading(true);
    try {
      const { data } = await api.getCurfewsService();
      dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCurfews();
  }, []);

  const cardLabel = (curfew) => {
    const { name, schedules } = curfew;
    const [schedule] = schedules;
    const { startDate, startTime, duration } = schedule;
    const localStartDate = new Date(`${startDate} ${startTime}`);

    // Caluclating end time from duration ie. start date + duration = end date
    const localEndDate = addDuration(duration, localStartDate);

    // Mapping the response from api to user locale
    const dayAdjustment = localStartDate.getDay() - localStartDate.getUTCDay();
    const adjustedDays = adjustDaysOfTheWeek(
      schedules[0].recurrenceRules[0].dayOfTheWeek,
      weekDaysOptions,
      dayAdjustment,
      locale,
    )
      .filter(({ selected }) => selected)
      .map(({ label }) => label)
      .join(' ');

    return (
      <>
        <div className={stylesMonitoring['card-label-name']}>{name}</div>
        <div className={stylesMonitoring['card-label-days']}>{adjustedDays}</div>
        <div className={stylesMonitoring['card-label-hours']}>{`${formatLocalDate(
          localStartDate,
          'h:mma',
        )} - ${formatLocalDate(localEndDate, 'h:mma')} `}</div>
      </>
    );
  };

  const onItemEdit = (curfew) => {
    setSelectedCurfew(curfew);
    setView('editItem');
  };

  const updateCurfew = async (formValues, selectedCurfew, setSubmitting, setStatus) => {
    const updatedCurfew = saveCurfewAlert(formValues, curfew, { curfew: selectedCurfew }, locale, weekDays);
    if (updatedCurfew) {
      try {
        dispatch(setLoadingStatus(true));
        await api.updateCurfewsService(updatedCurfew);
        curfewId = selectedCurfew.lastSvcReqId;
        startPolling(updateSuccessMsg, updateErrorMsg);
      } catch (error) {
        dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data: null });
        getCurfews();
        dispatch(setLoadingStatus(false));
        setStatus && setStatus({ success: false });
        setSubmitting && setSubmitting(false);
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: updateErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const validationCallback = (response: APIResponse<CurfewResponse>) => {
    const curfew = response.data.svcRequests.find((item) => item.id === curfewId);
    const curfewStatus = curfew?.status;

    if (curfewStatus?.includes(Status.SUCCESS) || curfew === undefined) {
      dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data: response.data });
      return PollingStatus.SUCCESS;
    }

    if (curfewStatus?.includes(Status.FAILED)) {
      dispatch({ type: CURFEW_ACTIONS.GET_CURFEWS, data: response.data });
      return PollingStatus.ERROR;
    }

    return PollingStatus.PENDING;
  };

  const successfulCallback = (message: string) => {
    onFormClose();
    getCurfews();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: successDialogHeader } });
  };

  const errorCallback = (message: string) => {
    onFormClose();
    getCurfews();
    dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message, title: errorDialogHeader } });
  };

  const attemptsExceededCallback = () => {
    onFormClose();
    getCurfews();
    setAttemptsExceededDialog();
  };

  const closeModal = () => {
    setAttemptsExceededDialog(false);
  };

  const startPolling = (successMsg: string, errorMsg: string) => {
    initiatePolling({
      pollingFunc: api.getCurfewsService.bind(api),
      validationCallback,
      successCallback: successfulCallback.bind(this, successMsg),
      errorCallback: errorCallback.bind(this, errorMsg),
      warningCallback: attemptsExceededCallback,
    });
  };

  const createCurfew = async (formValues, setSubmitting, setStatus) => {
    const createdCurfew = saveCurfewAlert(formValues, curfew, null, locale, weekDays);
    if (createdCurfew) {
      try {
        dispatch(setLoadingStatus(true));
        const { data: curfewResponse } = await api.createCurfewsService(createdCurfew);
        curfewId = curfewResponse.svcReqId;
        startPolling(createSuccessMsg, createErrorMsg);
      } catch (err) {
        setStatus({ success: false });
        setSubmitting(false);
        dispatch(setLoadingStatus(false));
        dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: createErrorMsg, title: errorDialogHeader } });
      }
    }
  };

  const onFormConfirm = (formValues, setSubmitting, setStatus) => {
    const nameRepeated = curfew?.curfews.find(
      (item) => item.name === formValues.name && item?.id !== selectedCurfew?.id,
    );
    if (nameRepeated) {
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: nameErrorMsg, title: errorDialogHeader } });
    } else {
      formValues.dayOfTheWeek = mapDaysFromLang(formValues.dayOfTheWeek, locale);
      if (!!selectedCurfew) {
        updateCurfew(formValues, selectedCurfew, setSubmitting, setStatus);
      } else {
        createCurfew(formValues, setSubmitting, setStatus);
      }
    }
  };

  const onItemDelete = async (selectedCurfew) => {
    try {
      dispatch(setLoadingStatus(true));
      await api.deleteCurfewsService(selectedCurfew);
      curfewId = selectedCurfew.id;
      startPolling(deleteSuccessMsg, deleteErrorMsg);
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch({ type: DIALOG_ACTIONS.SHOW, data: { message: deleteErrorMsg, title: errorDialogHeader } });
    }
  };

  const onFormClose = () => {
    setSelectedCurfew(null);
    setView('default');
    dispatch(setLoadingStatus(false));
  };

  const toggleCurfewState = (selectedCurfew) => {
    const { lastKnownState } = selectedCurfew;
    const updatedState = lastKnownState === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const getCurfewValues = mapFormInitialValues(selectedCurfew);

    const updateCurfewValues = { ...getCurfewValues, lastKnownState: updatedState };

    updateCurfew(updateCurfewValues, selectedCurfew);
  };

  const onChangeItemWarning = (selectedCurfew) => {
    const { inVehicleWarning } = selectedCurfew;
    const getCurfewValues = mapFormInitialValues(selectedCurfew);

    if (!inVehicleWarning) {
      trackEvent(displayAlertInvehicleToggleOnAnalyticsEventName);
    } else trackEvent(displayAlertInvehicleToggleOffAnalyticsEventName);

    const updateCurfewValues = { ...getCurfewValues, inVehicleWarning: !inVehicleWarning };

    updateCurfew(updateCurfewValues, selectedCurfew);
  };

  const renderCurfewForm = (curfew = null) => {
    const initialValues = curfew ? mapFormInitialValues(curfew) : defaultFormValues;
    return (
      <CurfewForm
        label={fieldLabel}
        description={tabDescription}
        placeholder={fieldPlaceholder}
        errorMessage={fieldErrorMessage}
        initialValues={initialValues}
        switchLabel={switchDescription}
        onFormConfirm={onFormConfirm}
        onFormClose={onFormClose}
        tabDescription={tabDescription}
        cancelButtonLabel={cancelButton}
        confirmButtonLabel={cancelConfirm}
        dayLabel={dayLabel}
        daysSelectDayEndLabel={daysSelectDayEndLabel}
        daysSelectDayStartLabel={daysSelectDayStartLabel}
        daysValidationErrorMessage={daysValidationErrorMessage}
        weekLabel={weekLabel}
        formNameError={formNameError}
        atLeastOneDayOfTheWeekLabel={atLeastOneDayOfTheWeekMsg}
        analyticsEventName={{
          curfewLimitAnalyticsEventName,
          saveAnalyticsEventName,
          cancelAnalyticsEventName,
        }}
      />
    );
  };

  const curfewAlertCount = curfew?.curfews?.length || 0;
  const renderAlertsCount = () =>
    !!curfewAlertCount && (
      <AlertsCount
        label={alertSetLabel}
        countCurrent={curfew.curfews.length}
        countMax={curfewsLimit}
        preposition={alertSetPreposition}
      />
    );

  const variant = curfew?.curfews?.length >= curfewsLimit ? 'outlined' : 'contained';
  const defaultContent = curfew?.curfews?.length ? '' : defaultViewContent;

  if (isLoading) return <Loader style={{ height: '100%' }} />;

  return (
    <>
      <ActiveView currentView={view} activeView={'default'}>
        <TabHeader
          label={title}
          content={defaultContent}
          onClickInfo={() => {
            trackEvent(iButtonAnalyticsEventName);
            setView('tabInfo');
          }}
          onEdit={() => {
            trackEvent(editAnalyticsEventName);
            setView('editList');
          }}
          displayEditButton={curfewAlertCount > 0}
        />
        <div className={stylesMonitoring['card-list']}>
          {curfew?.curfews?.map((curfew) => {
            const isChecked = curfew.lastKnownState === 'ACTIVE';
            return (
              <ManageCardItem
                key={curfew.id}
                label={cardLabel(curfew)}
                handlers="switch"
                isChecked={isChecked}
                onSwitchToggle={() => toggleCurfewState(curfew)}
              />
            );
          })}
        </div>
        <div className={stylesMonitoring['button-container--curfew']}>
          <Button
            variant={variant}
            onClick={() => {
              trackEvent(addNewAnalyticsEventName);
              setView('addItem');
            }}
            disabled={curfew?.curfews?.length >= curfewsLimit}
          >
            {defaultViewButtonLabel}
          </Button>
        </div>
        {renderAlertsCount()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'editList'}>
        <TabHeader label={title} onEdit={() => setView('default')} onClickInfo={() => setView('tabInfo')} />
        <div className={stylesMonitoring['card-list']}>
          {curfew?.curfews?.map((curfew) => {
            return (
              <ManageCardItem
                key={curfew.id}
                label={cardLabel(curfew)}
                handlers="edit"
                onSwitchToggle={() => onChangeItemWarning(curfew)}
                onItemEdit={() => onItemEdit(curfew)}
                onItemRemove={() => onItemDelete(curfew)}
              />
            );
          })}
        </div>
        <div className={stylesMonitoring['button-container--curfew']}>
          <Button variant="outlined" onClick={() => setView('default')}>
            {cancelButton}
          </Button>
        </div>
        {renderAlertsCount()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'addItem'}>
        <TabHeader label={editViewTitle} />
        {renderCurfewForm()}
      </ActiveView>

      <ActiveView currentView={view} activeView={'editItem'}>
        <TabHeader label={editFormViewTitle} />
        {selectedCurfew && renderCurfewForm(selectedCurfew)}
      </ActiveView>

      <ActiveView currentView={view} activeView={'tabInfo'}>
        <TabInfo onClose={() => setView('default')}>
          <TabHeader label={title} content={infoBoxContent} showInfoIcon />
        </TabInfo>
      </ActiveView>

      <Modal
        open={attemptsExceededDialog}
        onClose={closeModal}
        primaryControl={
          <Button variant="contained" onClick={closeModal}>
            Ok
          </Button>
        }
      >
        <p>{attemptsExceededMessage}</p>
      </Modal>
    </>
  );
}
export default TabCurfew;
