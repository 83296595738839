import { VariantInfo } from '@customTypes/PackageInfo';
import { OfferType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export const isEligiblePaid = (variant: VariantInfo) => {
  const PAID_PKG_OFFER_TYPES = [
    OfferType.Base_discount,
    OfferType.Promotional,
    OfferType.PromotionalClassAVariableTerm,
    OfferType.Promo_code,
  ];
  if (!variant) return false;
  return (
    !variant.discounts?.length ||
    variant.discounts?.filter((d) => PAID_PKG_OFFER_TYPES.includes(d.offerType)).length > 0
  );
};
