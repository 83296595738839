import React from 'react';
import { Stack } from '@mui/material';
import { Container, ContentCenter } from '../../styled-components/globalStyles';
import { PaidPackages } from '../Packages';
import { LegalContainer, PackagesLegal } from '../Legal';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../Types';
import {
  CommonWebContent,
  ContentfulAssets,
  DiscountWebContent,
  PackagesWebContent,
  TrialPackagesWebContent,
} from '../Subscription';
import { PaidPackagesProps } from '../Packages/PaidPackage/PaidPackages';
import PackageSection from '@manageSubscription/Packages/PaidPackage/PackageSection';
import Button from '@app/components-lib/components/Button';
import { useLabelContext } from '@components/LabelContext';
import { SubscriptionLabels } from '@components/SubscriptionWrapper/SubscriptionLabels';

export type ManagePaidPackagesProps = {
  farthestSubscriptions: SubscribedPackageInfo[];
  noMorePackagesAvailable: string;
  canUserSubscribeToPackages: boolean;
  onlyTrialInPackages: boolean;
  hasPaidPackageSelected: boolean;
  eligiblePaidPackages: EligiblePackageInfo[];
  isTrialEligible: boolean;
  canShowBackButton: boolean;
  customerCareCancelPackageNumber: string;
  location: string;
  commonWebContent: CommonWebContent;
  packagesWebContent: PackagesWebContent;
  trialPackagesWebContent: TrialPackagesWebContent;
  discountWebContent: DiscountWebContent;
  assets: ContentfulAssets;
  getHighestSubscriptionHeaderText: () => string;
  handleAddServices: () => void;
  navigateForward: () => void;
  navigateBack?: () => void;
} & Omit<PaidPackagesProps, 'packages' | 'termsLabel' | 'currentPackageLabel' | 'perTermsLabel' | 'tenantId'>;

const ManagePaidPackages: React.FC<ManagePaidPackagesProps> = ({
  farthestSubscriptions,
  noMorePackagesAvailable,
  canUserSubscribeToPackages,
  onlyTrialInPackages,
  hasPaidPackageSelected,
  eligiblePaidPackages,
  isTrialEligible,
  canShowBackButton,
  customerCareCancelPackageNumber,
  location,
  commonWebContent,
  packagesWebContent,
  trialPackagesWebContent,
  discountWebContent,
  assets,
  getHighestSubscriptionHeaderText,
  handleAddServices,
  navigateForward,
  navigateBack,
  ...paidPackagesProps
}) => {
  const { tenantId } = paidPackagesProps.subscriptionProps;

  const {
    manageSubscriptionDict: {
      trialEligibleHeader,
      trialEligibleSubheader,
      packageSelectionTrialTermsDisclaimer,
      skipAndContinue,
      back,
      addServices,
      packagesDescription,
    },
    packagePerTermDict,
    packageTermsDict,
  } = useLabelContext<SubscriptionLabels>();

  const header = isTrialEligible ? trialEligibleHeader : getHighestSubscriptionHeaderText();
  const subHeader = isTrialEligible ? trialEligibleSubheader : packagesDescription;

  const continueButton =
    onlyTrialInPackages && !isTrialEligible ? (
      <Button name="continueBtn" onClick={navigateForward} fullWidth>
        {skipAndContinue}
      </Button>
    ) : (
      <Button
        name="continueBtn"
        data-testid="paid-pkg-continue-button"
        onClick={handleAddServices}
        disabled={!hasPaidPackageSelected}
        fullWidth
      >
        {addServices}
      </Button>
    );

  return (
    <Container>
      {canUserSubscribeToPackages ? (
        <>
          <PackageSection header={header} subHeader={subHeader}>
            <PaidPackages
              {...paidPackagesProps}
              discountWebContent={discountWebContent}
              tenantId={tenantId}
              perTermsLabel={packagePerTermDict}
              currentPackageLabel={packagesWebContent.currentPackageLabel}
              commonWebContent={commonWebContent}
              assets={assets}
              location={location}
              termsLabel={packageTermsDict}
              packages={eligiblePaidPackages}
            />
          </PackageSection>
          <Stack alignItems="flex-end" mt="3rem">
            <Stack gap="1rem" sx={(theme) => ({ width: '100%', [theme.breakpoints.up('lg')]: { maxWidth: '15rem' } })}>
              {continueButton}
              {canShowBackButton && (
                <Button variant="outlined" onClick={navigateBack} fullWidth>
                  {back}
                </Button>
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        <ContentCenter>{noMorePackagesAvailable}</ContentCenter>
      )}

      {isTrialEligible && packageSelectionTrialTermsDisclaimer ? (
        <LegalContainer legalText={packageSelectionTrialTermsDisclaimer} showCheckbox={false} />
      ) : (
        <PackagesLegal />
      )}
    </Container>
  );
};

export default ManagePaidPackages;
