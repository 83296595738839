import React, { useMemo } from 'react';
import { AssetObject } from '@utils/assets';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { getSortedServices } from '../AllBenefits/allBenefitsUtils';
import { ServiceDescriptions } from '@components/Cancellation/cancellationUtils';
import Markdown from '@components/Markdown';
import ServiceItem from '../../ServiceItem';
import useToggle from '@hooks/useToggle';
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Modal, TAX_NAMES } from '@components-lib';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';
import useVehicle from '@api/queries/useVehicle';
import { useCancelMachineContext } from '@components/Cancellation/CancelSubscriptionStateMachineWrapper';
import { useFeatureFlags } from '@components/FeatureFlags';
import { getTaxRateFromTaxLineItems, getTaxFromTaxRate } from '@utils/tax';

interface Labels {
  currentPrice: string;
  regularPrice: string;
  packageDetails: string;
  offerDetails: string;
  getDeal: string;
  promoHeader: string;
  notification: string;
  offerDetailsContent: string;
  descriptions: ServiceDescriptions;
  defaultShortDescription: string;
  defaultLongDescription: string;
  servicesHierarchy: string[];
  currencyLabel: string;
}

interface Props {
  offerPackage: EligiblePackageInfo;
  onSelect: (pkg: EligiblePackageInfo) => void;
  labels: Labels;
  assets?: AssetObject;
}

const PromoPackage = ({ offerPackage, onSelect, labels = {}, assets = {} }: Props) => {
  const [packageDetailsOpened, setPackageDetailsOpened] = useToggle(false);
  const [offerDetailsOpened, setOfferDetailsOpened] = useToggle(false);
  const { shouldIncludeTax } = useFeatureFlags();
  const { step } = useCancelMachineContext();

  const {
    data: { capableServices = [], vin },
  } = useVehicle();

  const {
    currentPrice,
    regularPrice,
    packageDetails,
    offerDetails,
    getDeal,
    promoHeader,
    notification,
    offerDetailsContent,
    descriptions,
    defaultShortDescription,
    defaultLongDescription,
    servicesHierarchy = [],
    currencyLabel,
  } = labels;
  const { variant, isSelected } = offerPackage;

  const reorderedServices = useMemo(() => {
    const services = offerPackage.services;
    return services?.length
      ? getSortedServices(servicesHierarchy, offerPackage.services)
      : getSortedServices(servicesHierarchy, capableServices);
  }, [capableServices, offerPackage.services, servicesHierarchy]);

  const taxRate = variant?.taxLineItems?.length
    ? getTaxRateFromTaxLineItems(variant?.taxLineItems, TAX_NAMES.VAT_TAX)
    : 0.0;
  const listPriceWithTax = taxRate ? variant.listPrice + getTaxFromTaxRate(taxRate, variant.listPrice) : 0.0;
  const actualPriceWithTax = taxRate ? variant.actualPrice + getTaxFromTaxRate(taxRate, variant.actualPrice) : 0.0;

  return (
    <>
      <Grid
        container
        sx={(theme) => ({
          border: `1px solid ${theme.palette.primary.main}`,
          minHeight: '300px',
          [theme.breakpoints.up('sm')]: {
            minHeight: '250px',
          },
        })}
      >
        <Grid
          xs={12}
          sm={6}
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
          })}
        >
          <Typography
            variant="h3"
            textAlign="center"
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
            })}
          >
            {promoHeader}
          </Typography>
          <AnalyticsButton
            variant="text"
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              mt: 'auto',
            })}
            onClick={() => setPackageDetailsOpened(true)}
            analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
              text: packageDetails,
              pageName: step,
            })}
          >
            {packageDetails}
          </AnalyticsButton>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,

            [theme.breakpoints.up('sm')]: {
              alignItems: 'flex-start',
            },
          })}
        >
          <Typography variant="h5" sx={{ fontSize: 'inherit', textDecoration: 'line-through' }}>
            {regularPrice} {currencyLabel}
            {shouldIncludeTax ? (-Math.abs(listPriceWithTax)).toFixed(2) : variant.listPrice}
          </Typography>
          <Typography
            sx={(theme) => ({
              textWrap: 'pretty',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                textAlign: 'left',
              },
            })}
            variant="h4"
          >
            {currentPrice} {currencyLabel}
            {shouldIncludeTax ? (-Math.abs(actualPriceWithTax)).toFixed(2) : variant.actualPrice}
          </Typography>
          <Typography>{notification}</Typography>
          <AnalyticsButton
            variant="text"
            onClick={() => setOfferDetailsOpened(true)}
            analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
              text: offerDetails,
              pageName: step,
            })}
          >
            {offerDetails}
          </AnalyticsButton>
          <AnalyticsButton
            sx={(theme) => ({
              width: '100%',
              mt: 'auto',
              [theme.breakpoints.up('sm')]: {
                width: '75%',
              },
              [theme.breakpoints.up('md')]: {
                width: '50%',
              },
            })}
            variant={isSelected ? 'contained' : 'outlined'}
            onClick={() => onSelect(offerPackage)}
            analyticsEvent={new EventDataBuilder(EventType.EnrollCancelClick).withArgs({
              text: getDeal,
              pageName: step,
              vin,
              enrollPackages: [offerPackage],
            })}
          >
            {getDeal}
          </AnalyticsButton>
        </Grid>
      </Grid>
      <Modal
        title={offerPackage.packageName}
        open={packageDetailsOpened}
        showXButton
        onClose={() => setPackageDetailsOpened(false)}
      >
        <Stack spacing={2}>
          {reorderedServices?.map((service) => (
            <ServiceItem
              service={service}
              assets={assets}
              descriptions={descriptions}
              defaultShortDescription={defaultShortDescription}
              defaultLongDescription={defaultLongDescription}
              key={service.vehicleServiceId}
            />
          ))}
        </Stack>
      </Modal>
      <Modal open={offerDetailsOpened} showXButton onClose={() => setOfferDetailsOpened(false)}>
        <Markdown>{offerDetailsContent}</Markdown>
      </Modal>
    </>
  );
};

export default PromoPackage;
