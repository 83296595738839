import { SubscriptionProps } from '@manageSubscription';
import { APPLE_PAY_MERCHANT_CAPABILITIES, COUNTRY_DETAIL_LOOKUP, SubscriptionInfo } from '../Types';

export const buildApplePayRequestInfo = (
  subscriptionProps: SubscriptionProps,
  subscriptionInfo: SubscriptionInfo,
  displayName: string,
) => {
  const {
    applePaySupportedNetworks,
    userDetails: {
      billingAddress: { country },
    },
  } = subscriptionProps;
  const { totalAmount } = subscriptionInfo;
  const { currencyCode, countryCode } = COUNTRY_DETAIL_LOOKUP[country];
  return {
    countryCode,
    currencyCode,
    merchantCapabilities: APPLE_PAY_MERCHANT_CAPABILITIES,
    supportedNetworks: applePaySupportedNetworks,
    total: { label: displayName, amount: totalAmount },
  } as any;
};
