import { ApplePayProps } from './Types';
import { createApplePaySession, decryptApplePayToken } from '../services/cps';
import { useCallback, useState } from 'react';
import { CONFIG_KEYS, SubscriptionProps } from '@manageSubscription';

type ApplePayPaymentAuthorizedEvent = ApplePayJS.ApplePayPaymentAuthorizedEvent;

export const useApplePay = (applePayProps: ApplePayProps, subscriptionProps: SubscriptionProps) => {
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [error, setError] = useState(null);

  const { config } = subscriptionProps;
  const { request, displayName, subscriptionInfo } = applePayProps;
  const { totalAmount } = subscriptionInfo;

  const createPayment = useCallback(() => {
    const applePayDomain = config.getOemValue(CONFIG_KEYS.APPLE_PAY_DOMAIN);
    const merchantId = config.get(CONFIG_KEYS.APPLE_PAY_MERCHANT_ID);

    const session = new ApplePaySession(3, request);
    session.begin();

    session.onvalidatemerchant = () => {
      createApplePaySession(subscriptionProps, {
        displayName,
        domain: applePayDomain,
        initiative: 'web',
        merchantId,
      })
        .then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch((err) => {
          setError(err);
          console.error('Error while creating merchant session: ', err);
        });
    };
    try {
      session.onpaymentmethodselected = function () {
        const payItem = { label: displayName, amount: totalAmount };
        const update = { newTotal: payItem, newLineItems: [] };
        session.completePaymentMethodSelection(update);
      };
    } catch (err) {
      setError(err);
      console.error('Error during applePay paymentMethod selection: ', err);
    }
    session.onpaymentauthorized = function (event: ApplePayPaymentAuthorizedEvent) {
      const {
        payment: { token },
      } = event;
      decryptApplePayToken(subscriptionProps, {
        merchantId,
        paymentToken: token.paymentData,
      })
        .then(({ paymentMethodId: paymentMethodIdResp }) => {
          if (paymentMethodIdResp) {
            setPaymentMethodId(paymentMethodIdResp);
            session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
            console.log('ApplePay payment completed successfully');
          }
        })
        .catch((err) => {
          setError(err);
          setPaymentMethodId(null);
          session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
        });
    };
    return session;
  }, []);

  return [createPayment, paymentMethodId, error];
};
