import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../Types';
import { EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';

export const buildSubscribedPackages = (subscribedPackages) => {
  return subscribedPackages.map(
    (subscribedPackage: SubscribedPackage) =>
      ({
        ...subscribedPackage,
        packageName: subscribedPackage.marketingName,
        variant: {
          id: subscribedPackage.packageVariantId,
          initialTerm: subscribedPackage.initialTerm,
          initialTermUnit: subscribedPackage.initialTermUnit,
          actualPrice: subscribedPackage.listPrice,
          listPrice: subscribedPackage.listPrice,
          startDate: subscribedPackage.startDate,
          endDate: subscribedPackage.endDate,
          promotionalMessage: subscribedPackage.promotionalMessage,
          longDescription: subscribedPackage.longDescription,
          renewable: subscribedPackage.autoRenew,
          renewalTerm: subscribedPackage.renewalTerm,
          requiresAutoRenew: subscribedPackage.autoRenew,
          taxTotal: subscribedPackage.taxTotal,
          cancelEffectiveDate: subscribedPackage.cancelEffectiveDate,
          discounts: subscribedPackage.discounts,
          termServiceEndDate: subscribedPackage.termServiceEndDate,
        },
      } as SubscribedPackageInfo),
  );
};

export const buildEligiblePackages = (eligiblePackages) => {
  return eligiblePackages.map(
    (eligiblePackage: EligiblePackagesResponse) =>
      ({
        ...eligiblePackage,
        variant: null,
      } as EligiblePackageInfo),
  );
};

export const buildPackage = (givenPackage: EligiblePackageInfo): PackageSubscription => {
  const {
    id,
    parentId,
    packageName,
    marketingName,
    tier,
    packageType,
    basePriceMonthly,
    isPkgSubscribed,
    variant,
    products,
    bundle,
    isSelected,
    autoSelectIds,
    isAutoSelected,
    disabled,
  } = givenPackage;
  return {
    id,
    parentId,
    packageName,
    marketingName,
    tier,
    packageType,
    basePriceMonthly,
    isPkgSubscribed,
    variant,
    products,
    bundle,
    isSelected,
    autoSelectIds,
    isAutoSelected,
    disabled,
  } as PackageSubscription;
};
