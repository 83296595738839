import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo, VariantInfo } from '../Types';
import { PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import findIndex from 'lodash/findIndex';
import {
  ANNUAL_TERM_IN_MONTHS,
  CPS_TIMEZONE,
  DEFAULT_SUBSCRIPTION_LOW_TIER,
  MAX_PKG_TERM_IN_MONTHS,
  MAX_PKG_TIER,
  MIN_PKG_TIER,
  PAID_PKG_OFFER_TYPES,
  VALID_PKG_SUBSCRIPTION_DAYS,
} from './constants';
import { isDefaultPackage, isPaidPackage } from './packageUtils';
import { isHighestTrialSubscription, isTrialPackage, hasGoodwill } from './trialPackageUtils';
import { TenantsSupportOnlyUpgrade } from './productFlowMap';
import { differenceInDays, isFuture } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatToCpsTZ = (date: Date = new Date()) => formatInTimeZone(date, CPS_TIMEZONE, 'yyyy-MM-dd');

export const hasActiveSubscription = (packages: SubscribedPackageInfo[]) => {
  return packages?.some(
    (pkg) => pkg.active && (isTrialPackage(pkg.variant) || isPaidPackage(pkg.variant) || hasGoodwill(pkg.variant)),
  );
};

export const isLastInactivePackageLessThanXDays = (
  subscribedPackages: SubscribedPackageInfo[],
  days = VALID_PKG_SUBSCRIPTION_DAYS,
) => {
  // Check if customer's last inactive subscription is <= 60 days from today
  const lastInactivePkg = subscribedPackages
    ?.filter((sPkg) => !sPkg.active)
    .reduce((highest, current, index) => {
      return index === 0 || current.variant.cancelEffectiveDate > highest.variant.cancelEffectiveDate
        ? current
        : highest;
    }, null);

  if (!lastInactivePkg?.variant.cancelEffectiveDate) {
    return true;
  }
  return differenceInDays(new Date(formatToCpsTZ()), new Date(lastInactivePkg.variant.cancelEffectiveDate)) <= days;
};

export const deDupeFarthestSubscriptions = (packages: SubscribedPackageInfo[]) => {
  if (!packages?.length) {
    return [];
  }
  // De-duplicate packages having farthest endDate
  return packages.reduce((state, current) => {
    let prevIndex = findIndex(state, (x) => x.packageName.toLowerCase() === current.packageName.toLowerCase());
    if (prevIndex === -1) {
      state.push(current);
    } else if (state[prevIndex].variant.endDate < current.variant.endDate) {
      state[prevIndex] = current;
    }
    return state;
  }, []);
};

export const getHighestPaidSubscription = (packages: SubscribedPackageInfo[]) => {
  return getHighestSubscription(packages?.filter((pkg) => pkg.tier !== null && isPaidSubscription(pkg.variant)));
};

export const getHighestSubscription = (subscribedPackages: SubscribedPackageInfo[]) => {
  if (!subscribedPackages?.length) {
    return DEFAULT_SUBSCRIPTION_LOW_TIER;
  }
  return subscribedPackages.reduce((highest, current) => {
    if (!current.tier) {
      current.tier = MIN_PKG_TIER;
    }
    return current.tier < highest.tier ? current : highest;
  }, subscribedPackages[0]);
};

/**
 * Returns highest subscribed package when tier value is present else returns null (tier = 1 being higher)
 * Util used in the case of tiered packages
 */
export const findHighestSubscription = (sPackages: SubscribedPackageInfo[]): SubscribedPackageInfo | null => {
  return sPackages.reduce((max, current) => {
    if (current.tier === null) return max;
    if (max === null || max.tier === null) return current;
    return current.tier < max.tier ? current : max;
  }, null as SubscribedPackageInfo | null);
};

export const isPaidSubscription = (variant: VariantInfo) => {
  if (!variant) {
    return false;
  }
  const { discounts } = variant;
  return !discounts?.length || discounts.filter((d) => PAID_PKG_OFFER_TYPES.includes(d.offerType)).length > 0;
};

export const creditCardVariant = (variant?: VariantInfo) => {
  if (!variant) {
    return false;
  }
  const { discounts = [] } = variant;
  return discounts.some((discount) => discount.ccRequired);
};

export const getPaidPackage = (packages: PackageSubscription[], subscribedPackages: SubscribedPackageInfo[]) => {
  const paidPackage = packages.find((pkg) => isPaidPackage(pkg.variant));
  if (paidPackage) {
    return paidPackage;
  }
  const subscribedPackage = subscribedPackages.find((sPkg) => isHighestPaidSubscription(sPkg));
  if (!subscribedPackage) {
    return;
  }
  const sVariant = subscribedPackage.variant;
  return { ...subscribedPackage, variant: { ...sVariant, id: subscribedPackage.subscriptionPackageId } };
};

export const getSelectedPackage = (
  packages: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
): PackageSubscription | SubscribedPackageInfo | undefined => {
  const paidPackage = getPaidPackage(packages, subscribedPackages);
  if (paidPackage) {
    return paidPackage;
  }
  const trialPackage = packages.find((pkg) => isTrialPackage(pkg.variant));
  if (trialPackage) {
    return trialPackage;
  }
};

export const isHighestPaidSubscription = (pkg: SubscribedPackageInfo) => {
  return (
    pkg.tier === MAX_PKG_TIER && pkg.variant.initialTerm == MAX_PKG_TERM_IN_MONTHS && isPaidSubscription(pkg.variant)
  );
};

export const canSubscribeToPackages = (
  sPackages: SubscribedPackageInfo[],
  ePackages: EligiblePackageInfo[],
  tenantId: string,
) => {
  // If tenant supports both upgrade and downgrade then return true, so that user can
  // continue subscription
  if (!TenantsSupportOnlyUpgrade[tenantId]) {
    return true;
  }
  // Users cannot subscribe to packages, if they already have highest subscription
  // and has no eligible addOns available for given tenant
  return !(
    sPackages.some((sPkg) => isHighestPaidSubscription(sPkg)) &&
    !ePackages.some((ePkg) => ePkg.packageType === PackageType.Add_on)
  );
};

export const isAnnualTerm = ({ currentTermUnit, currentTerm }: SubscribedPackageInfo) =>
  currentTermUnit === TermUnit.Months && currentTerm >= ANNUAL_TERM_IN_MONTHS;

export const containsMonthlyOnly = (sPackages: SubscribedPackageInfo[]) => {
  return sPackages?.every((sPkg) => isPaidPackage(sPkg.variant) && !isAnnualTerm(sPkg));
};

export const containsDefaultsOnly = (sPackages: SubscribedPackageInfo[]) => {
  return sPackages?.every(isDefaultPackage);
};

export const calculateSubscriptionTotals = (subscribedPackages: SubscribedPackageInfo[]) => {
  return subscribedPackages.reduce(
    (totals, pkg) => {
      const taxTotal = Number(pkg.variant.taxTotal);
      const taxAmount = taxTotal && !Number.isNaN(taxTotal) ? taxTotal : 0.0;
      totals.totalPrice += Number(pkg.amountWithoutTax);
      totals.totalTaxes += taxAmount;
      return totals;
    },
    { totalPrice: 0, totalTaxes: 0 },
  );
};

const isHighestSubscription = (pkg: SubscribedPackageInfo) => {
  return isHighestTrialSubscription(pkg) || isHighestPaidSubscription(pkg);
};

export const isFuturePackage = (pkg: EligiblePackageInfo) => {
  return isFuture(new Date(pkg.startDate));
};
