import React, { useState } from 'react';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../../Types';
import {
  CommonWebContent,
  ContentfulAssets,
  ContentMap,
  DiscountWebContent,
  SubscriptionProps,
} from '../../Subscription';
import {
  determinePackageOrBundle,
  findPackageByProduct,
  getMatchingProductBundle,
  getProductMatchingBundle,
  hasPackageAdded,
  isAllProductsFromBundleSubscribed,
  isTiered,
} from '../../utils';
import Spinner from '../../../components/Spinner';
import { Container } from './styles';
import EligibleBundle from '../Bundle/EligibleBundle';
import EligiblePackage from './EligiblePackage';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { Collapse } from '@mui/material';
import Button from '@app/components-lib/components/Button';
import { useLabelContext } from '@components/LabelContext';
import { SubscriptionLabels } from '@components/SubscriptionWrapper/SubscriptionLabels';

export interface ViewPackagesProps {
  eligiblePackages?: EligiblePackageInfo[];
  currentPackageLabel: string;
  perTermsLabel: ContentMap;
  commonWebContent: CommonWebContent;
  discountWebContent: DiscountWebContent;
  assets: ContentfulAssets;
  subscriptionProps: SubscriptionProps;
  location: string;
  subscribedPackages: SubscribedPackageInfo[];
  packageSubscriptions: PackageSubscription[];
  removePackage: (pkg: EligiblePackageInfo) => void;
  addPackage: (pkg: EligiblePackageInfo) => void;
  removeDiscount: () => void;
  eligibleTrialPackages?: EligiblePackageInfo[];
  isLoading: boolean;
}

export const viewPackagesTitle = 'Please select packages';

const ViewPackages = ({
  eligiblePackages = [],
  location,
  currentPackageLabel,
  perTermsLabel,
  commonWebContent,
  discountWebContent,
  assets,
  subscriptionProps,
  subscribedPackages,
  packageSubscriptions,
  removePackage,
  addPackage,
  removeDiscount,
  eligibleTrialPackages = [],
  isLoading,
}: ViewPackagesProps) => {
  const {
    manageSubscriptionDict: { numberOfPackagesToShow, showLessPackages, showMorePackages },
  } = useLabelContext<SubscriptionLabels>();

  const numPkgToShow = Number(numberOfPackagesToShow);
  const [isOpen, setIsOpen] = useState(false);

  const displayedCollapsedPackages = () => {
    let displayedPackages: EligiblePackageInfo[] = [];
    let collapsedPackages: EligiblePackageInfo[] = [];
    if (eligiblePackages.length && numPkgToShow) {
      displayedPackages = eligiblePackages.slice(0, numPkgToShow);
      collapsedPackages = eligiblePackages.slice(numPkgToShow);
    } else {
      displayedPackages = eligiblePackages;
    }

    return { displayedPackages, collapsedPackages };
  };

  const { displayedPackages, collapsedPackages } = displayedCollapsedPackages();

  const isSubscribedOnBundle = eligiblePackages.find(({ isPkgSubscribed, bundle }) => isPkgSubscribed && bundle);

  const isPackageForbiddenForSelect = (ePackage: EligiblePackageInfo, isAlreadySelected: boolean) =>
    isSubscribedOnBundle &&
    !isAlreadySelected &&
    isAllProductsFromBundleSubscribed(
      getMatchingProductBundle(eligiblePackages, ePackage),
      eligiblePackages,
      packageSubscriptions,
      [],
      ePackage,
    );

  const onPackageSelect = (selected: EligiblePackageInfo) => () => {
    const isPackageAdded = hasPackageAdded(packageSubscriptions, selected);
    const isForbidden = isPackageForbiddenForSelect(selected, isPackageAdded);
    const bundleOrPackage =
      isTiered(selected.tier) || selected.bundle || isForbidden
        ? selected
        : determinePackageOrBundle(eligiblePackages, packageSubscriptions, subscribedPackages, selected);

    const isPackageOrBundleExists = hasPackageAdded(packageSubscriptions, bundleOrPackage);
    managePackage(isPackageOrBundleExists ? selected : bundleOrPackage, !isPackageOrBundleExists);
  };

  const managePackage = (selected: EligiblePackageInfo, isAddPackage: boolean) => {
    isAddPackage ? addPackage(selected) : removePackage(selected);
    removeDiscount();

    if (isTiered(selected.tier)) {
      let maxTier = Math.min(...packageSubscriptions.map((pkg) => pkg.tier));
      if (selected.tier < maxTier) {
        maxTier = selected.tier;
      }
      const packagesThatHaveLowerTier = packageSubscriptions.filter((pkg) => pkg.tier > maxTier);
      packagesThatHaveLowerTier.forEach((pkg) => {
        removePackage(pkg);
      });
      const trialsWithSameTier = eligibleTrialPackages?.filter((pkg) => pkg.tier === selected.tier);
      trialsWithSameTier
        ?.filter((trialPkg) => !packageSubscriptions.some((pkg) => pkg.tier === trialPkg.tier))
        .forEach((pkg) => addPackage(pkg));
      return;
    }
    if (selected.bundle && isAddPackage) {
      removeAllProducts(selected.products);
      return;
    }
    const matchingBundle = getMatchingProductBundle(eligiblePackages, selected);
    if (matchingBundle && !isAddPackage && hasPackageAdded(packageSubscriptions, matchingBundle)) {
      removePackage(matchingBundle);
      addProductsOtherThanSelected(matchingBundle.products, selected);
    }
  };

  const addProductsOtherThanSelected = (products: Product[], selected: EligiblePackageInfo) => {
    products.forEach(({ id, overlapAllowed }) => {
      const matchingFromBundle = findPackageByProduct(eligiblePackages, id, false);
      if (!overlapAllowed && selected.variant.id !== matchingFromBundle.variant.id) {
        addPackage(matchingFromBundle);
      }
    });
  };

  const removeAllProducts = (products: Product[]) => {
    products.forEach(({ id, overlapAllowed }) => {
      const matchingFromBundle = findPackageByProduct(eligiblePackages, id, false);
      if (!overlapAllowed && hasPackageAdded(packageSubscriptions, matchingFromBundle)) {
        removePackage(matchingFromBundle);
      }
    });
  };

  const renderPackage = (item: EligiblePackageInfo, index: number) => {
    const { isPkgSubscribed, bundle } = item;
    const isPackageAdded = hasPackageAdded(packageSubscriptions, item);
    const productMatchingBundle = getProductMatchingBundle(packageSubscriptions, item);
    const isForbidden = isPackageForbiddenForSelect(item, isPackageAdded);

    return (
      <React.Fragment key={index}>
        {bundle ? (
          <EligibleBundle
            packageBundle={item}
            commonWebContent={commonWebContent}
            perTermsLabel={perTermsLabel}
            selected={isPkgSubscribed || isPackageAdded}
            onSelect={onPackageSelect(item)}
            assets={assets}
            currentPackageLabel={currentPackageLabel}
          />
        ) : (
          <EligiblePackage
            pkgIndex={index}
            eligiblePackage={item}
            subscriptionProps={subscriptionProps}
            commonWebContent={commonWebContent}
            discountWebContent={discountWebContent}
            location={location}
            productMatchingBundle={productMatchingBundle}
            assets={assets}
            perTermsLabel={perTermsLabel}
            currentPackageLabel={currentPackageLabel}
            selected={isPkgSubscribed || isPackageAdded || !!productMatchingBundle}
            onSelect={onPackageSelect(item)}
            isForbidden={isForbidden}
          />
        )}
      </React.Fragment>
    );
  };

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container title={viewPackagesTitle}>
      {displayedPackages.map(renderPackage)}

      <Collapse sx={{ width: '100%' }} in={isOpen} timeout="auto" unmountOnExit>
        {collapsedPackages.map(renderPackage)}
      </Collapse>
      {numPkgToShow && eligiblePackages.length ? (
        <Button
          variant="text"
          sx={(theme) => ({
            alignSelf: 'flex-start',
            marginTop: theme.spacing(3),
          })}
          onClick={handleExpandClick}
        >
          {isOpen ? showLessPackages : showMorePackages}
        </Button>
      ) : null}
    </Container>
  );
};
export default ViewPackages;
