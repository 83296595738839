import {
  PreviewOrderResponse,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useVehicle from './useVehicle';
import { createOrders, previewOrders, generateTransactionId, packagesToCancel } from '@app/components-lib';
import { getClientIp } from '@app/components-lib/services/commonService';
import { EligiblePackageInfo } from '@manageSubscription/Types';

import attachCapableServices from '@api/requests/subscription/attachCapableServices';
import { useGenerateSubscriptionProps } from '@components/SubscriptionWrapper/utils';
import { useGetPaymentMethods } from '@app/components-lib/services/cps/queries';
import useSubscribedPackages from './useSubscribedPackages';
import getFirstExisting from '@utils/getFirstExisting';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export type OrderParams = {
  packagesToRemove: SubscribedPackage[];
  packagesToAdd: EligiblePackageInfo[];
  reason: string;
  orderSummary: PreviewOrderResponse;
  agreementType?: AgreementType;
  location?: string;
  salesChannel: SalesChannel;
};

type UseOrdersProps = {
  salesChannel?: SalesChannel;
};

const useOrders = ({ salesChannel }: UseOrdersProps) => {
  const queryClient = useQueryClient();

  const {
    data: { capableServices = [] },
  } = useVehicle();

  const manageSubscriptionPayload = useGenerateSubscriptionProps();

  const { data: paymentInfo } = useGetPaymentMethods(manageSubscriptionPayload);

  const {
    data: { subscribedPackages },
  } = useSubscribedPackages();

  const { mutateAsync: createOrderMutation } = useMutation({
    mutationKey: ['createOrder', salesChannel],
    mutationFn: async ({
      packagesToRemove = [],
      packagesToAdd = [],
      orderSummary,
      reason,
      agreementType = AgreementType.Complete,
      location = '',
    }: OrderParams) => {
      const clientIP = await getClientIp();
      const transactionId = generateTransactionId();

      const skipAgreementCreation = subscribedPackages.length === packagesToRemove.length && !packagesToAdd.length;

      return await createOrders(
        { ...manageSubscriptionPayload, agreementType, location, salesChannel },
        packagesToAdd,
        packagesToRemove,
        packagesToCancel,
        orderSummary,
        paymentInfo[0],
        false,
        transactionId,
        clientIP,
        packagesToAdd.map(({ variant }) => variant.discounts),
        skipAgreementCreation,
        reason,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicle'] });
      queryClient.invalidateQueries({ queryKey: ['subscription'] });
      queryClient.invalidateQueries({ queryKey: ['subscribedPackages'] });
      queryClient.invalidateQueries({ queryKey: ['eligiblePackages'] });
    },
  });

  const { mutateAsync: previewOrderMutation } = useMutation({
    mutationKey: ['previewOrder', salesChannel],
    mutationFn: async ({ packagesToRemove = [], packagesToAdd = [], location = '' }: OrderParams) => {
      const response = await previewOrders(
        { ...manageSubscriptionPayload, location, salesChannel },
        packagesToAdd,
        packagesToRemove,
        getFirstExisting(
          paymentInfo,
          ['[0].paymentMethodId', 'creditCard[0].paymentMethodId', 'paypal[0].paymentMethodId'],
          '',
        ),
        packagesToAdd.map(({ variant }) => variant.discounts),
        packagesToCancel,
      );

      try {
        const toSubscribe = response.subscribedPackages.filter(({ packageType }) => packageType !== 'DEFAULT');
        const subscribedPackagesWithServices = attachCapableServices({
          packages: toSubscribe,
          capableServices,
        });
        response.subscribedPackages = subscribedPackagesWithServices;
      } catch (e) {
        console.warn('Error attaching capable services to subscribed packages');
      }

      return response;
    },
  });

  return {
    createOrder: createOrderMutation,
    previewOrder: previewOrderMutation,
  };
};

export default useOrders;
